import { InputField } from '@common/index';
import { useFormikContext } from 'formik';
import React from 'react';
import { useInlineUpdateField } from '../useInlineUpdateField';
import { Container, InputWithText, Placeholder } from './styled';
import { CommonFieldProps } from '../types';

export const DueDateField = ({ onUpdated }: CommonFieldProps) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineUpdateField(onUpdated);

  const context = useFormikContext();

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <InputWithText>
          <InputField
            name="endDateXDaysAfter"
            type="number"
            min={1}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
          <div>days after Work Order created</div>
        </InputWithText>
      </div>
    );
  }

  return (
    <Container onClick={() => setIsEdit(true)}>
      {context.values.endDateXDaysAfter > 0 && (
        <>
          {context.values.endDateXDaysAfter} day{context.values.endDateXDaysAfter > 1 ? 's' : ''} after Work Order
          created
        </>
      )}

      {!context.values.endDateXDaysAfter && <Placeholder>No date</Placeholder>}
    </Container>
  );
};
