import { ConnectHistory } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { ConnectIntegrationType } from '@features/Platform/Integrations';

export const useConnectIntegrationHistory = (args: {
  connectIntegrationId: number;
  integrationType: ConnectIntegrationType;
}) =>
  useQuery<ConnectHistory[]>(
    [ReactQueryKey.ConnectAuroraHistory, 'useConnectIntegrationHistory', args],
    async () => {
      try {
        return (
          await postGraphql<{ connectHistories: ConnectHistory[] }>(
            gql`
              query CONNECT_HISTORIES_LIST_QUERY($connectIntegrationId: Int!, $integrationType: String!) {
                connectHistories(
                  condition: { connectIntegrationId: $connectIntegrationId }
                  filter: { connectIntegration: { type: { equalTo: $integrationType } } }
                ) {
                  agreementName
                  agreementId
                  connectIntegrationId
                  coperniqProjectId
                  createdAt
                  id
                  projectName
                  sourceProjectId
                  status
                }
              }
            `,
            args
          )
        ).connectHistories;
      } catch (e) {
        throw apiErrorHandler('Error fetching connect integrations', e);
      }
    },
    {
      initialData: []
    }
  );
