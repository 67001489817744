import React from 'react';
import { FilterWithGroups } from '@components/Scheduler/components/Filters/FilterWithGroups';
import { useSystemAlertConfigList } from '@hooks/workspace/systems/alertConfigs/useSystemAlertConfigList';
import { useClientFilterState } from '../../useClientFilterState';

export const Alerts = () => {
  const { clientFilters, updateFilters } = useClientFilterState();
  const { data: alertsList = [] } = useSystemAlertConfigList();

  if (alertsList.length === 0) {
    return null;
  }

  return (
    <FilterWithGroups
      label="Alerts"
      fieldName="alerts"
      onSelect={({ alerts }) => updateFilters({ alerts })}
      groups={[
        {
          title: 'Alerts',
          options: alertsList.map(({ id, name }) => ({
            id,
            title: name
          }))
        }
      ]}
      selected={clientFilters.alerts}
      showAllIfNoneSelected
    />
  );
};
