import { useWorkOrderTemplate } from '@hooks/templates/workOrders/useWorkOrderTemplate';
import { TaskTemplate } from '@types';
import React, { useMemo } from 'react';
import { useRoutes } from '@hooks/useRoutes';
import { convertFromPropertyIdToTemplateMemberId } from '@utils/properties';
import { TaskTemplateForm } from './TaskTemplateForm';

interface Props {
  id: 'new' | number;
  onClose?: () => void;
  cloneFromId?: number;
  onCreated?: (values: any) => void;
}

const NEW_WORK_ORDER_TEMPLATE_INITIAL_VALUES: Partial<TaskTemplate> = {
  id: -1,
  title: '',
  position: 0,
  priority: 2,
  description: '',
  templateUsers: [],
  subTasks: [],
  labels: [],
  forms: [],
  startDate: undefined,
  endDate: undefined,
  isColored: false,
  isTemplate: true,
  isField: true,
  endDateXDaysAfter: undefined
};

export const WorkOrderTemplateForm = ({ id, cloneFromId, onCreated, onClose }: Props) => {
  const { companyId } = useRoutes();

  const isNew = id === 'new';
  const idToFetch = isNew ? cloneFromId : id;

  const { data, isLoading } = useWorkOrderTemplate(idToFetch ?? undefined);

  const initialValues = useMemo(() => {
    if (data) {
      let result = {
        ...data,
        title: cloneFromId ? `${data.title} (copy)` : data.title,
        id: isNew ? -1 : data.id,
        companyId
      };

      // TODO refactor this shit
      if (result?.templateUsers) {
        // taks template property mapping
        result = {
          ...result,
          templateUsers: [
            ...result.templateUsers,
            ...result.templateProperties.map((property) => ({
              ...property,
              id: convertFromPropertyIdToTemplateMemberId(property.id)
            }))
          ]
        };
      }

      if (result?.templateAssigneeProperty) {
        result = {
          ...result,
          assignee: {
            ...result.templateAssigneeProperty,
            id: convertFromPropertyIdToTemplateMemberId(result.templateAssigneeProperty.id)
          }
        };
      }

      return result;
    }

    return { ...NEW_WORK_ORDER_TEMPLATE_INITIAL_VALUES, companyId };
  }, [data, isNew, companyId, cloneFromId]);

  if (isLoading) {
    return null;
  }

  return <TaskTemplateForm key={id} initialValues={initialValues} onClose={onClose} onCreated={onCreated} />;
};
