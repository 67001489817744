import { SearchBar } from '@common/SearchBar';
import { usePropertyGroups } from '@hooks/usePropertyGroup';
import { RecordType } from '@types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Globe, PlusSquare } from 'react-feather';
import { colors } from '@styles';
import { useModal } from '@common/PromiseModal';
import { PropertyForm } from '@features/Platform/Properties/List/PropertyForm';
import { isEqual } from 'lodash';
import { FORM_FIELD_TYPE_CONFIGS, mapProjectPropertyToFormFieldType } from './helpers';
import {
  ProjectPropertiesGroupTitle,
  ProjectPropertiesScrollable,
  ProjectPropertiesSectionTitle,
  SidebarItem
} from './styled';

interface Props {
  usedProjectPropertyIds: Set<number>;
}

export const ProjectProperties = memo<Props>(
  ({ usedProjectPropertyIds }) => {
    const [search, setSearch] = useState('');
    const { openModal } = useModal();
    const { data: groups } = usePropertyGroups({ scope: RecordType.PROJECT, fullAccess: true });

    const filteredGroups = useMemo(() => {
      if (!groups) {
        return [];
      }

      let propertyIndex = 0;

      return groups
        .map((group) => ({
          ...group,
          properties: group.properties
            .filter(
              (property) =>
                !usedProjectPropertyIds.has(property.id as number) &&
                property?.name?.toLowerCase()?.includes(search.toLowerCase())
            )
            .map((property) => ({
              ...property,
              // eslint-disable-next-line no-plusplus
              index: propertyIndex++
            }))
        }))
        .filter((group) => group.properties.length > 0);
    }, [groups, search, usedProjectPropertyIds]);

    const handleAddNewProperty = useCallback(() => {
      openModal<void>(({ onClose }) => <PropertyForm groups={groups} scope={RecordType.PROJECT} onClose={onClose} />, {
        title: 'Create Property'
      });
    }, [openModal, groups]);

    return (
      <>
        <ProjectPropertiesSectionTitle>
          Project Properties
          <Globe size="16px" color="#9c9caa" />
          <PlusSquare onClick={handleAddNewProperty} size="20px" color={colors.green} />
        </ProjectPropertiesSectionTitle>

        <SearchBar value={search} onValueChange={setSearch} />

        <ProjectPropertiesScrollable>
          {filteredGroups.map((group) => (
            <div key={group.id}>
              <ProjectPropertiesGroupTitle>{group.name}</ProjectPropertiesGroupTitle>
              {group.properties.map((item) => (
                <Draggable key={item.id} draggableId={item.id.toString()} index={item.index}>
                  {(provided, snapshot) => (
                    <SidebarItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                      style={provided.draggableProps.style}
                    >
                      {FORM_FIELD_TYPE_CONFIGS[mapProjectPropertyToFormFieldType(item)].icon}
                      {item.name}
                    </SidebarItem>
                  )}
                </Draggable>
              ))}
            </div>
          ))}
        </ProjectPropertiesScrollable>
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.usedProjectPropertyIds, nextProps.usedProjectPropertyIds)
);
