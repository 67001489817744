import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Edit2, FileText, Plus } from 'react-feather';
import { Financial } from '@components/Project/Financial';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId, selectWorkspaceId, selectCompanyById } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess, hasEntityAccessNew } from '@utils/roles';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { InfoIcon } from '@kit/ui/icons/Info';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { ContextMenu } from '@kit/components/ContextMenu';
import { useRecordDetail } from '@hooks/useRecordDetail';

import { Accounting } from '@features/ClientPortfolio/Client/Overview/Accounting';
import { Docs as DocsWidget } from '@features/ClientPortfolio/Client/Overview/Docs';
import { Stakeholders } from '@features/ClientPortfolio/Client/Overview/Stakeholders';
import { Sales } from '@features/ClientPortfolio/Client/Overview/Sales';
import { Reminders } from '@features/ClientPortfolio/Client/Overview/Reminders';
import { RecordType } from '@types';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { GroupedDocList, useCreateDocMenuItems } from '@features/DocumentManagement/DocList';
import { stopPropagationClick } from '@utils';
import {
  Container,
  CreateWork,
  Details,
  DetailsContent,
  DetailsHeader,
  DetailsTitle,
  EditIconButton,
  Widget,
  WidgetDetailsButton,
  WidgetHeader,
  WidgetIcon,
  WidgetTitle
} from './styled';
import { Financials as FinancialsWidget } from './Financials';
import { Work } from './Work';

import { Properties as PropertiesWidget } from './Properties';

import { OverviewSkeleton } from './Skeleton';
import { EditProperties } from './Properties/FullProperties/EditProperties';

interface Props {
  projectId: number;
}

type TDetails = 'financials' | 'docs' | 'properties' | 'work';

export const Overview = ({ projectId }: Props) => {
  const { data: record, isLoading } = useRecordDetail(projectId, { refetchOnMount: false });

  const [expandedDetails, setExpandedDetails] = useState<TDetails | null>(null);
  const companyId = useAppSelector(selectWorkspaceId);
  const userId = useAppSelector(selectCurrentUserId);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const { data: access, isFetching } = useUserRoleSettings(companyId, userId);
  const canViewFinances = hasAccess(access, 'contract', 'view', 'contract');
  const canViewWork = hasAccess(access, 'task', 'view');
  const canViewDocs = hasAccess(access, 'file', 'view') || hasAccess(access, 'form', 'view');
  const canCreateForm = hasAccess(access, 'form', 'create');
  const canCreateFile = hasAccess(access, 'file', 'create');
  const canViewSales = !!company?.settings?.features?.connectIntegrations;

  const canEdit = record && hasEntityAccessNew(record, 'edit');

  const createFileMenuItems = useCreateDocMenuItems(projectId);

  const expandDetails = useCallback(
    (id: TDetails) => () => {
      if (id === 'properties' && (isLoading || record?.id !== projectId)) {
        return;
      }
      setExpandedDetails(id);
    },
    [isLoading, record, projectId]
  );

  useEffect(() => {
    setExpandedDetails(null);
  }, [projectId]);

  const details = useMemo(() => {
    if (!expandedDetails) {
      return null;
    }

    switch (expandedDetails) {
      case 'financials':
        return {
          title: 'Financials',
          content: <Financial recordId={projectId} />
        };
      case 'docs':
        return {
          title: 'Documents',
          content: <GroupedDocList recordId={projectId} recordType={RecordType.PROJECT} />
        };
      case 'properties':
        return {
          title: 'Properties'
        };
      default:
        return null;
    }
  }, [expandedDetails, projectId]);

  if (expandedDetails && details) {
    if (expandedDetails === 'properties') {
      return (
        <EditProperties
          projectId={projectId}
          recordType={RecordType.PROJECT}
          onClose={() => setExpandedDetails(null)}
        />
      );
    }

    return (
      <Details>
        <DetailsHeader>
          <DetailsTitle>{details.title}</DetailsTitle>
          <Button variant={ButtonVariant.Secondary} onClick={() => setExpandedDetails(null)}>
            Close
          </Button>
        </DetailsHeader>
        <DetailsContent>{details.content}</DetailsContent>
      </Details>
    );
  }

  if (isFetching) {
    return <OverviewSkeleton />;
  }

  return (
    <Container>
      <Widget size="large" isFullWidth>
        <WidgetHeader>
          <WidgetTitle onClick={expandDetails('properties')}>
            <WidgetIcon backgroundColor="#DFDFE8">
              <InfoIcon size="16px" color="#737382" />
            </WidgetIcon>
            Properties
          </WidgetTitle>
          {canEdit && (
            <EditIconButton onClick={expandDetails('properties')}>
              <Edit2 size="16px" />
            </EditIconButton>
          )}
          {!canEdit && (
            <WidgetDetailsButton onClick={expandDetails('properties')}>
              <ChevronRightIcon size={24} />
            </WidgetDetailsButton>
          )}
        </WidgetHeader>

        <PropertiesWidget projectId={projectId} />
      </Widget>

      {canViewWork && <Reminders projectId={projectId} />}

      {canViewWork && <Work projectId={projectId} />}

      {canViewDocs && (
        <Widget isFullWidth>
          <WidgetHeader>
            <WidgetTitle onClick={expandDetails('docs')}>
              <WidgetIcon backgroundColor="#DBE5FF">
                <FileText size="16px" color="#235DFF" />
              </WidgetIcon>
              Docs
              {(canCreateFile || canCreateForm) && (
                <CreateWork {...stopPropagationClick}>
                  <ContextMenu position="left" items={createFileMenuItems}>
                    <Button variant={ButtonVariant.Secondary}>
                      <Plus size="16px" />
                      Doc
                    </Button>
                  </ContextMenu>
                </CreateWork>
              )}
            </WidgetTitle>
            <WidgetDetailsButton onClick={expandDetails('docs')}>
              <ChevronRightIcon size={24} />
            </WidgetDetailsButton>
          </WidgetHeader>

          <DocsWidget projectId={projectId} />
        </Widget>
      )}

      <Stakeholders title="Stakeholders" addButtonText="Stakeholder" isFullWidth projectId={projectId} />

      {canViewSales && <Sales isFullWidth projectId={projectId} />}

      {canViewFinances && (
        <Widget isFullWidth>
          <WidgetHeader>
            <WidgetTitle onClick={expandDetails('financials')}>
              <WidgetIcon backgroundColor="#CDF3DF">
                <DollarIcon size="16px" color="#009A47" />
              </WidgetIcon>
              Financials
            </WidgetTitle>
            <WidgetDetailsButton onClick={expandDetails('financials')}>
              <ChevronRightIcon size={24} />
            </WidgetDetailsButton>
          </WidgetHeader>

          <FinancialsWidget projectId={projectId} />
        </Widget>
      )}

      {canViewFinances && <Accounting recordId={projectId} />}
    </Container>
  );
};
