export const config = (() => {
  const PROTOCOL = process.env.PROTOCOL || 'https';
  const WS_PROTOCOL = PROTOCOL === 'https' ? 'wss' : 'ws';
  const API_DOMAIN = process.env.API_DOMAIN || 'develop.coperniq.dev';
  const SERVICE_PROJECT = process.env.SERVICE_PROJECT || '/project-service';
  const PORTAL_URL = process.env.PORTAL_URL || 'https://portal.coperniq.io';
  const SERVICE_USER = process.env.SERVICE_USER || '/user-service';
  const SERVICE_DATABANK = process.env.SERVICE_DATABANK || '/databank-service';
  const SERVICE_WS_COMMON = process.env.SERVICE_WS_COMMON || '';
  const SERVICE_WS_PROJECT = process.env.SERVICE_WS_PROJECT || '';
  const SERVICE_NOTIFICATION = process.env.SERVICE_NOTIFICATION || '/notification-service';
  const SERVICE_FLEET = process.env.SERVICE_FLEET || '/fleet-service';
  const SERVICE_CONNECT = process.env.SERVICE_CONNECT || '/connect-service';
  const SIGHT_ENGINE_API_URL = process.env.SIGHT_ENGINE_API_URL || 'https://api.sightengine.com/1.0';
  const SIGHT_ENGINE_API_USER = process.env.SIGHT_ENGINE_API_USER || '176713624';
  const SIGHT_ENGINE_API_SECRET = process.env.SIGHT_ENGINE_API_SECRET || 'oHy3yRprxkFDMtCZDr4B';
  const BEAM_INSTANCE_ID = process.env.BEAM_INSTANCE_ID || '5d22b44c-c7e2-48e4-bbd5-8bf9d3a2fa2f';
  const ZENDESK_API_KEY = process.env.ZENDESK_API_KEY || '83fe3780-9390-4e87-8a9c-1681943c9794';
  const CHURNZERO_APP_KEY = process.env.CHURNZERO_APP_KEY || '1!MDFxd5o0IMUkUyL8xd3oxav-vYcwVMipxKcOYko664kt1451';
  const MAPBOX_API_URL = process.env.MAPBOX_API_URL || '';
  const MAPBOX_API_TOKEN = process.env.MAPBOX_API_TOKEN || '';
  const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN || '';

  const BASE_URL = `${PROTOCOL}://${API_DOMAIN}`;

  const joinUrl = (path: string) => [BASE_URL, path].join('');

  return {
    STAND: process.env.STAND || 'local',
    baseHttpUrl: BASE_URL,
    portalUrl: PORTAL_URL,
    projectServiceUrl: joinUrl(SERVICE_PROJECT),
    dataBankServiceUrl: joinUrl(SERVICE_DATABANK),
    userServiceUrl: joinUrl(SERVICE_USER),
    notificationServiceUrl: joinUrl(SERVICE_NOTIFICATION),
    fleetServiceUrl: joinUrl(SERVICE_FLEET),
    connectServiceUrl: joinUrl(SERVICE_CONNECT),
    socketIoUrl: `${WS_PROTOCOL}://${API_DOMAIN}${SERVICE_WS_COMMON}`,
    beamClientKey: BEAM_INSTANCE_ID,
    zendeskKey: ZENDESK_API_KEY,
    wsForm: `${WS_PROTOCOL}://${API_DOMAIN}${SERVICE_WS_PROJECT}`,
    sightEngine: {
      api_url: SIGHT_ENGINE_API_URL,
      api_user: SIGHT_ENGINE_API_USER,
      api_secret: SIGHT_ENGINE_API_SECRET
    },
    pspdfkitKey:
      API_DOMAIN === 'coperniq.dev'
        ? 'lYHZSbWxbuRykZdgvit3l8vhruDq-9z3UcvrQJdw7BIsR3NbCB9hfciAp7szNM5nb3lOa482jrNyr3LJqSlPoBOOK73pkvVLcnfnZXEK873a83WSGYfs7gRP1J8jTg-ILnRkarP5yHamzfrrpxYuN8txIxNxoC-Rxwvv9l0mD_B61GEf4XTQZMkUAcs0Ds2uUV3xeVqhhvP91rdWgOos4umdbxDoCRUd18kqk9nI_GuAUoYjfMargY12rigmlnwtcjn7GHMV7N_m0vN2UodkqKrLggfUUMViJcshmgCWoWQCo2pOVb-YNEuhExkONw7cm__5qQ2_e2HfiWd3DKzRczQEmEwDWv_YQKkd2CHYXNsSyyt0Fwxzt-gKqhafvIxBgv128QrplHblXGSr7CZDynYvkc5U9Dl70cQ0ejygFZ5Jfx64W268JH8RYAbPhvqkMKLQmsKC0gkHAI9wnU-ULEvhOTmPeBy3ujXODWDTZobQs0R52Y6FwpZIfpCs3Fm2t3RSj1Sa389Dw7vrn1kzyJ6Ew6QpH0xkd8oT1ghjl-j22SFY06G6ukWmBhNXeD-PC2Cz88BKhqi1C7gUZ8ssNLRDxoeWh3WbknKU8ixOr_5aa9R5NyIhtUuSu4-mVJAhat6S23VpuiZv7tGsaNJ3BQO9v_LtQqfhgVBBnN8rexybFUp4CYcmwBYgmILhnuoTYVpV9VOhYgJq_NAUGZPflGvZh5mW-mQkY4AF7VXKHm-3Fm_b1se6fvbuNTGYwIif9Tuvx4bCaOD-moPY5ZAPMHlHvNu_GvdGT10Nzy-lxxF4MzyFftQ0xWpd8a84e8MJYLm82aejCc_zOqMI6sr_ybvzRc6ST2wo4rA_2h0tDt8KyG--mLlENGadT-zMrvHbeubc19ZaIzEnM64oXRT0Zw=='
        : undefined,
    // pspdfkitKey: process.env.PSPDFKIT_KEY - for some reason does not load
    activeEnv: process.env.GATSBY_ACTIVE_ENV,
    oneschemaClientId: process.env.GATSBY_ONESCHEMA_CLIENT_ID || 'dccef65c-c97f-4f4f-8efd-02afc02d5ecc',
    CHURNZERO_APP_KEY,
    mapboxApiUrl: MAPBOX_API_URL,
    mapboxApiToken: MAPBOX_API_TOKEN,
    mapboxAccessToken: MAPBOX_ACCESS_TOKEN
  };
})();
