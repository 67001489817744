import { EventType, SingleDestination } from '@generated/types/graphql';
import {
  NoficationEventSettings,
  NotificationEventSettings
} from '@hooks/workspace/notifications/useCompanyNotifications';

export const SINGLE_DESTINATION_TITLES_MAP: Record<SingleDestination, string> = {
  [SingleDestination.Owner]: 'Owner',
  [SingleDestination.ProjectManager]: 'Project Manager',
  [SingleDestination.SalesRep]: 'Sales Rep',
  [SingleDestination.Assignee]: 'Assignee',
  [SingleDestination.Collaborator]: 'Collaborators',
  [SingleDestination.Mentions]: 'Mentioned User'
};

const ONLY_SIGNLE_DESTINATION_EVENTS: EventType[] = [
  EventType.MentionsAnywhere,
  EventType.RequestAppointmentScheduled,
  EventType.FutureRequestAppointmentScheduled,
  EventType.AssigneeAddedOrUpdated,
  EventType.CollaboratorsAddedOrUpdated,
  EventType.TodaysVisitScheduled,
  EventType.FutureVisitScheduled,
  EventType.ReminderOverdue,
  EventType.ReminderRescheduled,
  EventType.ReminderAssignedOrReassigned,
  EventType.GeofencingAutoClockInOut,
  EventType.GeofencingRemindClockInOut
];

const READONLY_RECEIVERS_EVENTS: EventType[] = [
  EventType.MentionsAnywhere,
  EventType.RequestAppointmentScheduled,
  EventType.FutureRequestAppointmentScheduled,
  EventType.ReminderOverdue,
  EventType.ReminderRescheduled,
  EventType.ReminderAssignedOrReassigned,
  EventType.TodaysVisitScheduled,
  EventType.FutureVisitScheduled,
  EventType.GeofencingAutoClockInOut,
  EventType.GeofencingRemindClockInOut
];

export const isOnlySingleDestinationEvent = (eventType: EventType) => {
  return ONLY_SIGNLE_DESTINATION_EVENTS.includes(eventType);
};

export const getSingleDestinationReceiversList = (event: NotificationEventSettings) => {
  return event.singleReceivers.map((receiver) => SINGLE_DESTINATION_TITLES_MAP[receiver]).join(', ');
};

export const isReadOnlyReceieversEvent = (eventType: EventType) => {
  return READONLY_RECEIVERS_EVENTS.includes(eventType);
};

/*
1. only 1 single destination, not editable (mention)
2. only 2 single destinations, not editable (todays visit scheduled)
3. only 2 single destinations, editable (assignee added or updated)
4. only 1 single destination, not editable (reminder/appointment assignee)
5. 3 single destinations, roles, teams editable
*/

export const getDeliveryMethodsString = (settings: NoficationEventSettings) => {
  const methods = [];

  if (settings.inboxEnabled) {
    methods.push('Inbox');
  }

  if (settings.emailEnabled) {
    methods.push('Email');
  }

  if (settings.pushEnabled) {
    methods.push('Push');
  }

  if (settings.smsEnabled) {
    methods.push('SMS');
  }

  return methods.join(', ');
};
