import React, { FC, useState, useCallback } from 'react';
import { useConnectIntegrationHistory } from '@hooks/workspace/connectIntegration';
import { ConnectIntegrationType, ConnectHistoryStatus } from '@features/Platform/Integrations';
import moment from 'moment';
import { debounce } from 'lodash/fp';
import { SearchBar } from '@common/SearchBar';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { Container, Table, TableContainer } from './styled';

const statusColors = {
  [ConnectHistoryStatus.SUCCESSFUL]: '#cdf3df',
  [ConnectHistoryStatus.FAILED]: 'rgb(247, 218, 221)',
  [ConnectHistoryStatus.IN_PROGRESS]: '##f0ad4e'
};

interface HistoryProps {
  connectIntegrationId: number;
}

export const History: FC<HistoryProps> = ({ connectIntegrationId }) => {
  const [search, setSearch] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChange = useCallback(debounce(300, setSearch), []);
  const { data: logs } = useConnectIntegrationHistory({
    connectIntegrationId,
    integrationType: ConnectIntegrationType.AURORA_SOLAR
  });

  return (
    <>
      <SearchBar placeholder="Search..." onValueChange={handleSearchValueChange} />
      <Container>
        <TableContainer>
          <Table>
            <thead>
              <th>Date & Time</th>
              <th>Project</th>
              <th>Status</th>
            </thead>
            <tbody>
              {logs
                .filter((log) =>
                  search ? log.projectName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true
                )
                .map((log) => (
                  <tr key={log.id} className="readonly">
                    <td>{moment(log.createdAt).format('MM/DD/YYYY h:mma')}</td>
                    <td>{log.projectName}</td>
                    <td>
                      <Badge size={BadgeSize.Large} color="#009688" bgColor={statusColors[log.status]}>
                        {log.status}
                      </Badge>
                    </td>
                  </tr>
                ))}
              {!logs.length && (
                <tr className="readonly">
                  <td colSpan={3}>
                    <div style={{ textAlign: 'center', padding: '20px' }}>No Data</div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};
