import { useSystemsGroups } from '@hooks/systems';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { mapClientFiltersToServerFilters, useClientFilterState } from './useClientFilterState';

export const useSystemsGroupsState = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    clientFilters: { groupBy, sortBy: orderBy, perPage, query, monitored, linking, status, source, fleet, alerts }
  } = useClientFilterState();

  const { condition, filter } = mapClientFiltersToServerFilters({
    companyId,
    status,
    linking,
    source,
    fleet,
    alerts,
    query,
    monitored
  });

  const {
    data: { groups, allIds },
    isLoading,
    isError,
    error
  } = useSystemsGroups({
    groupBy: groupBy?.id || null,
    orderBy: orderBy.map(({ option, isDesc }) => [option.id, isDesc]),
    first: perPage.value,
    offset: 0,
    condition,
    filter
  });

  return {
    data: { groups, allIds },
    isLoading,
    isError,
    error
  };
};
