import { post, put, deleteRequest, get } from '@services/api/base';
import {
  AuroraProject,
  AuroraAgreement,
  CreateConnectIntegrationDTO,
  UpdateConnectIntegrationDTO
} from '@features/Platform/Integrations';
import { ConnectIntegration } from '@generated/types/graphql';
import { Urls } from '@services/api/base/axios';

const BASE_URL = `${Urls.connect}/aurora`;

export const auroraIntegrationApi = {
  createIntegration: (dto: CreateConnectIntegrationDTO) => post<ConnectIntegration>(`${BASE_URL}`, dto),

  updateIntegration: (dto: UpdateConnectIntegrationDTO) => put<ConnectIntegration>(`${BASE_URL}`, dto),

  deleteIntegration: (id: number) => deleteRequest(`${BASE_URL}/${id}`),

  getProjects: (tenantId: string, token: string) =>
    get<AuroraProject[]>(`${BASE_URL}/projects?tenantId=${tenantId}&token=${token}`),

  getAgreements: (tenantId: string, token: string, projectId: string) =>
    get<AuroraAgreement[]>(`${BASE_URL}/agreements?tenantId=${tenantId}&token=${token}&projectId=${projectId}`)
};
