import styled from 'styled-components';
import { EditableFieldContainer } from '../styled';

export const Container = styled(EditableFieldContainer)`
  display: flex;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #9c9caa;
`;

export const InputWithText = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  font-size: 14px;

  > *:first-child {
    flex: 0 0 80px;
    min-width: 80px;
    width: 80px;
  }
`;
