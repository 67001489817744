import React from 'react';
import { Filters } from '@features/SystemPortfolio/List/Header/Filters';
import { Search } from './Search';
import { Container, FilterWrapper } from './styled';
import { GroupBy } from './GroupBy';
import { SortBy } from './SortBy';
import { Status } from './Status';
import { Fleet } from './Fleet';
import { Alerts } from './Alerts';

export const Header = () => {
  return (
    <Container>
      <FilterWrapper>
        <Search />
        <Status />
        <Fleet />
        <Alerts />
        <GroupBy />
        <SortBy />
        <Filters />
      </FilterWrapper>
    </Container>
  );
};
