import React, { FC } from 'react';
import { SelectField } from '@kit/components/Form';
import { Control } from 'react-hook-form';
import { getFullName } from '@utils/utils';
import DropDownItem from '@common/Selector/UserSelector/DropDownItem';
import { useAllCompaniesUsers } from '@hooks';
import { Container, Table, TableContainer } from './styled';
import { propertiesToMap, auroraPropertyOptions } from './MapPropertiesConfig';

interface HistoryProps {
  control: Control;
  search: string;
}

export const MapPropertiesTable: FC<HistoryProps> = ({ control, search }) => {
  const { data: companyUsers } = useAllCompaniesUsers();

  return (
    <Container>
      <TableContainer>
        <Table>
          <thead>
            <th style={{ width: '50%' }}>Aurora Solar</th>
            <th style={{ width: '50%' }}>Coperniq</th>
          </thead>
          <tbody>
            {propertiesToMap
              .filter((item) =>
                search ? `${item.coperniqEntity} > ${item.fieldLabel}`.toLocaleLowerCase().includes(search) : true
              )
              .map((propertyMap) => (
                <tr className="readonly">
                  <td style={{ width: '50%' }}>
                    {propertyMap.inputText === 'user' ? (
                      <SelectField
                        name={propertyMap.name}
                        control={control}
                        options={companyUsers}
                        getOptionLabel={getFullName}
                        noOptionsText="User not found"
                        renderOption={(option: any) => (
                          <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
                        )}
                      />
                    ) : (
                      <SelectField
                        control={control}
                        name={propertyMap.name}
                        options={auroraPropertyOptions}
                        isClearable={false}
                        isMulti
                        disabled={propertyMap.isDisabled}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    )}
                  </td>
                  <td style={{ width: '50%' }}>{`${propertyMap.coperniqEntity} > ${propertyMap.fieldLabel}`}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
