import React from 'react';
import { FilterWithGroups } from '@components/Scheduler/components/Filters/FilterWithGroups';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { useClientFilterState } from '../../useClientFilterState';

export const Fleet = () => {
  const { clientFilters, updateFilters } = useClientFilterState();
  const { data: fleetList = [] } = useFleetList();

  if (fleetList.length === 0) {
    return null;
  }

  return (
    <FilterWithGroups
      label="Fleet"
      fieldName="fleet"
      onSelect={({ fleet }) => updateFilters({ fleet })}
      groups={[
        {
          title: 'Fleet',
          options: fleetList.map(({ id, name }) => ({
            id,
            title: name
          }))
        }
      ]}
      selected={clientFilters.fleet}
      showAllIfNoneSelected
    />
  );
};
