import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { capitalizeFirstLetter } from '@utils';
import { InputLabel } from '@common/ui';

import { inputMui } from '../../../../styles';

const styles = inputMui({ height: '40px' });
const useStyles = makeStyles(styles);

const Wrapper = styled.div``;

type Props = {
  label?: string;
  name?: string;
  placeholder?: string;
  validate?: boolean;
  type?: string;
  component?: JSX.Element;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  readOnly?: boolean;
  required?: boolean;
  min?: number;
};

const InputField: React.FC<Props> = (props) => {
  const {
    label,
    name,
    placeholder = label,
    validate,
    type = 'text',
    component = TextField,
    className,
    onFocus,
    onBlur,
    readOnly = false,
    required,
    min
  } = props;

  const classes = useStyles();

  return (
    <Wrapper className={className}>
      {label && (
        <InputLabel htmlFor={name} required={required}>
          {label}
        </InputLabel>
      )}
      <Field
        classes={classes}
        component={component}
        name={name}
        type={type}
        placeholder={placeholder}
        variant="outlined"
        validate={validate}
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{ min }}
        // eslint-disable-next-line
        InputProps={{ readOnly }}
        data-testid={`field${capitalizeFirstLetter(name)}`}
      />
    </Wrapper>
  );
};

export default InputField;
