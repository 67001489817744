import React, { FC } from 'react';
import { SelectField } from '@kit/components/Form';
import { Control } from 'react-hook-form';
import { RecordType } from '@types';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector, useCompanyPropertiesWithoutRouter } from '@hooks';
import { Container, Table, TableContainer } from './styled';
import { auroraPropertyOptions } from './MapPropertiesConfig';

interface HistoryProps {
  control: Control;
  search: string;
}

export const CustomPropertiesTable: FC<HistoryProps> = ({ control, search }) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { allProperties = [] } = useCompanyPropertiesWithoutRouter(companyId, { recordType: RecordType.PROJECT });

  return (
    <Container>
      <TableContainer>
        <Table>
          <thead>
            <th style={{ width: '50%' }}>Aurora Solar</th>
            <th style={{ width: '50%' }}>Coperniq</th>
          </thead>
          <tbody>
            {allProperties
              .filter(
                (property) =>
                  property.isAdditional &&
                  property.scope.includes(RecordType.PROJECT) &&
                  (search ? `Project > ${property.name}`.toLocaleLowerCase().includes(search) : true)
              )
              .map((property) => (
                <tr className="readonly">
                  <td style={{ width: '50%' }}>
                    <SelectField
                      control={control}
                      name={`additional_${property.id}`}
                      options={auroraPropertyOptions}
                      isClearable
                      isMulti
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  </td>
                  <td style={{ width: '50%' }}>{`Project > ${property.name}`}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
