import React from 'react';
import { Flag } from 'react-feather';
import { Badge } from '@common/Badge';
import { defaultTheme } from '@themes';
import { ProjectDetail } from '@types';
import { getRedSlaOverdueInDays, getTimeInStageInDays, getYellowSlaOverdueInDays } from '@utils';

interface SlaFlagProps {
  record: ProjectDetail;
  className?: string;
}

const baseStyles = {
  color: defaultTheme.colors.white,
  font: defaultTheme.typefaces.text
};

const warningStyles = {
  backgroundColor: defaultTheme.colors.yellow,
  ...baseStyles
};
const alertStyles = {
  backgroundColor: defaultTheme.colors.red,
  ...baseStyles
};
const infoStyles = {
  ...baseStyles,
  backgroundColor: defaultTheme.colors.gray2Bg,
  color: defaultTheme.colors.black
};

export const SlaFlag: React.FC<SlaFlagProps> = ({ record, className }) => {
  const redSlaOverdue = getRedSlaOverdueInDays(record);
  const yellowSlaOverdue = getYellowSlaOverdueInDays(record);
  const timeInStage = getTimeInStageInDays(record);

  const overdueDays = redSlaOverdue || yellowSlaOverdue;

  if (!overdueDays && !timeInStage) {
    return null;
  }

  if (!overdueDays && timeInStage) {
    return (
      <Badge className={className} style={infoStyles}>
        {timeInStage}d
      </Badge>
    );
  }

  return (
    <Badge className={className} style={(redSlaOverdue && alertStyles) || (yellowSlaOverdue && warningStyles)}>
      <Flag color={defaultTheme.colors.white} />
      {overdueDays}d
    </Badge>
  );
};
