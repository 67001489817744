import React, { useMemo, useCallback } from 'react';
import { FilterWithGroups } from '@components/Scheduler/components/Filters/FilterWithGroups';
import { SOURCE_OPTIONS } from '@features/SystemPortfolio/constants';
import { useAppSelector } from '@hooks/index';
import { selectCompanySettings } from '@state/selectors';
import { useIntegrations } from '@hooks/workspace/systems/integrations/useIntegrations';
import { LinkingType, MonitoredType, useClientFilterState } from '../../useClientFilterState';

export const Filters = () => {
  const {
    clientFilters: { monitored, linking, source },
    updateFilters
  } = useClientFilterState();
  const { data: installedIntegrations } = useIntegrations();
  const settings = useAppSelector(selectCompanySettings);
  const isMockShown = settings?.features?.fleetMock;

  const filteredSourceOptions = useMemo(
    () =>
      SOURCE_OPTIONS.filter(({ provider, isMock }) => {
        if (isMock && !isMockShown) {
          return false;
        }
        const activeIntegration = installedIntegrations?.find((integration) => integration.provider === provider);

        return Boolean(activeIntegration);
      }).map((option) => ({
        ...option,
        id: option.provider
      })),
    [installedIntegrations, isMockShown]
  );

  const groups = useMemo(() => {
    const baseGroups: { title: string; options: { id: string; title: string }[] }[] = [
      {
        title: 'Linking',
        options: [
          { id: 'Linked', title: 'Linked' },
          { id: 'Not Linked', title: 'Not Linked' }
        ]
      },
      {
        title: 'Monitoring',
        options: [
          { id: 'Monitored', title: 'Monitored' },
          { id: 'Not Monitored', title: 'Not Monitored' }
        ]
      }
    ];

    if (filteredSourceOptions.length > 0) {
      baseGroups.unshift({
        title: 'Source',
        options: filteredSourceOptions.map((option) => ({ id: option.provider, title: option.name }))
      });
    }

    return baseGroups;
  }, [filteredSourceOptions]);

  const handleFilterChange = useCallback(
    ({ filters }: { filters: string[] }) => {
      const isMonitoredType = (value: string): value is MonitoredType => ['Monitored', 'Not Monitored'].includes(value);
      const isLinkingType = (value: string): value is LinkingType => ['Linked', 'Not Linked'].includes(value);

      updateFilters({
        monitored: filters.filter(isMonitoredType),
        linking: filters.filter(isLinkingType),
        source: filteredSourceOptions.filter((option) => filters.includes(option.provider))
      });
    },
    [filteredSourceOptions, updateFilters]
  );

  const selectedFilters = useMemo(
    () => [...monitored, ...linking, ...source.map(({ provider }) => provider)],
    [monitored, linking, source]
  );

  return (
    <FilterWithGroups
      label="Filters"
      fieldName="filters"
      onSelect={({ filters }) => handleFilterChange({ filters: filters as unknown as string[] })}
      groups={groups}
      selected={selectedFilters as unknown as number[]}
      showAllIfNoneSelected
    />
  );
};
