import styled from 'styled-components';
import { EditableFieldContainer } from '../styled';

export const ViewContainer = styled(EditableFieldContainer)<{ isOverdue?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ isOverdue }) => isOverdue && '#D54855'};
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #9c9caa;
`;
