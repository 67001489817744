import { colors } from '@styles';
import styled from 'styled-components';

export const Breadcrumbs = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: ${colors.green};
    text-decoration: none;
  }
`;

export const Container = styled.div`
  padding: 16px 0;
  max-width: 700px;
`;

export const Header = styled.div`
  padding: 24px 0;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const ArrowContainer = styled.div`
  text-align: center;
`;

export const SectionContainer = styled.div`
  margin-top: 40px;
  padding: 24px;
  background-color: ${colors.white};
`;

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const SubTitle2 = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;

export const MatchDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
  margin-bottom: 16px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: end;
  gap: 10px;
`;
