import { ReactQueryKey } from '@enums';
import { useDebouncedState } from '@hooks/useDebouncedState';
import React, { useCallback, useEffect } from 'react';
import { useUpdateEffect } from '@react-hookz/web';
import { RecordType } from '@types';
import { Tray } from '../components/Tray';
import { mapSearchFilterToServerSearchFilter } from '../useClientFilterState';
import { usePaginatedTasks } from '../useTasks';
import { ClientTrayFilters, useClientTrayFilterState } from '../useClientTrayFilterState';

interface Props {
  isCollapsed: boolean;
  onToggleIsCollapsed: () => void;
}
export const SchedulerTray = ({ isCollapsed, onToggleIsCollapsed }: Props) => {
  const [filters, setClientFilters] = useClientTrayFilterState();
  const [search, debouncedSearch, setSeacrh] = useDebouncedState(filters.query, 300);

  useEffect(() => {
    setClientFilters((prev: any) => ({
      ...prev,
      query: debouncedSearch
    }));
  }, [debouncedSearch, setClientFilters]);

  useUpdateEffect(() => {
    setSeacrh(filters.query);
  }, [filters.query]);

  const { tasks, total, fetchNextPage, isLoading } = usePaginatedTasks({
    queryKey: ReactQueryKey.SchedulerTrayTasks,
    filters: {
      isClosed: { equalTo: false },
      status: filters.statuses.length > 0 ? { in: filters.statuses } : undefined,
      templateTaskId: filters.templates.length > 0 ? { in: filters.templates } : undefined,
      startDate: { isNull: true },
      endDate: { isNull: true },
      taskVisitsByTaskIdExist: false,
      and: [
        filters.clientStatuses.length || filters.projectStatuses.length || filters.requestStatuses.length
          ? {
              project: {
                or: [
                  {
                    type: { equalTo: RecordType.PROJECT },
                    status: { in: filters.projectStatuses }
                  },
                  {
                    type: { equalTo: RecordType.DEAL },
                    requestStatus: { in: filters.requestStatuses }
                  },
                  {
                    type: { equalTo: RecordType.ACCOUNT },
                    accountStatus: { in: filters.clientStatuses }
                  }
                ]
              }
            }
          : {},
        {
          ...mapSearchFilterToServerSearchFilter(debouncedSearch.trim())
        }
      ]
    },
    perPage: 50
  });

  const handleFiltersChange = useCallback(
    (filters: Partial<ClientTrayFilters>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...filters
      }));
    },
    [setClientFilters]
  );

  return (
    <Tray
      tasks={tasks}
      total={total}
      isLoading={isLoading}
      searchValue={search}
      onSearchChange={setSeacrh}
      amountLabel="Unscheduled"
      filters={filters}
      onFiltersChange={handleFiltersChange}
      isCollapsed={isCollapsed}
      onToggleIsCollapsed={onToggleIsCollapsed}
      onFetchNextPage={fetchNextPage}
    />
  );
};
