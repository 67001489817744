import { ConnectIntegration } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';

export const useConnectIntegrations = (args: { companyId: number }) =>
  useQuery<ConnectIntegration[]>(
    [ReactQueryKey.ConnectIntegration, 'useConnectIntegrations', args],
    async () => {
      try {
        return (
          await postGraphql<{ connectIntegrations: ConnectIntegration[] }>(
            gql`
              query CONNECT_INTEGRATION_LIST_QUERY($companyId: Int!) {
                connectIntegrations(condition: { companyId: $companyId }) {
                  id
                  type
                  createdAt
                  token
                  tenant
                  webhookId
                }
              }
            `,
            args
          )
        ).connectIntegrations;
      } catch (e) {
        throw apiErrorHandler('Error fetching connect integrations', e);
      }
    },
    {
      initialData: []
    }
  );
