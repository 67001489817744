import { useCallback } from 'react';
import { useSessionStorageValue } from '@react-hookz/web';
import { LocalStorageKey } from '@enums';
import { SystemFilter, SystemStatus } from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { ClientFilterState, LinkingType, MonitoredType, queryToExpr, SourceOption } from '@hooks/systems';
import { merge } from 'lodash';
import { DEFAULT_CLIENT_FILTER_STATE } from '@features/SystemPortfolio/constants';

export const mapClientFiltersToServerFilters = ({
  companyId,
  linking,
  status,
  source,
  fleet,
  alerts,
  monitored,
  query
}: {
  companyId: number;
  linking: LinkingType[];
  status: SystemStatus[];
  source: SourceOption[];
  fleet: number[];
  alerts: number[];
  query: string;
  monitored: MonitoredType[];
}): {
  condition: { monitored?: boolean };
  filter: DeepPartial<SystemFilter>;
} => {
  const filters: DeepPartial<SystemFilter> = {
    integration: { companyId: { equalTo: companyId } }
  };

  if (linking.length === 1) {
    if (linking.includes('Linked')) {
      filters.projectId = { isNull: false };
    } else if (linking.includes('Not Linked')) {
      filters.projectId = { isNull: true };
    }
  }

  if (status.length > 0) {
    filters.status = { in: status };
  }

  if (source.length > 0) {
    filters.integration = {
      ...filters.integration,
      provider: { in: source.map(({ provider }) => provider) }
    };
  }

  if (fleet.length > 0) {
    filters.profile = { id: { in: fleet } };
  }

  if (alerts.length > 0) {
    filters.systemAlertsBySystemId = { some: { id: { in: alerts } } };
  }

  return {
    condition: {
      monitored: mapClientMonitoredToServerMonitored(monitored)
    },
    filter: merge(filters, queryToExpr(query))
  };
};

export const mapClientMonitoredToServerMonitored = (monitored: MonitoredType[]) => {
  if (monitored.length === 0 || (monitored.includes('Monitored') && monitored.includes('Not Monitored'))) {
    return undefined;
  } else if (monitored.includes('Monitored')) {
    return true;
  } else if (monitored.includes('Not Monitored')) {
    return false;
  }

  return undefined;
};

export const useClientFilterState = () => {
  const { value: clientFilters, set: setClientFilters } = useSessionStorageValue<ClientFilterState>(
    LocalStorageKey.systemsClientFilterState,
    {
      defaultValue: DEFAULT_CLIENT_FILTER_STATE
    }
  );

  const handleFiltersChange = useCallback(
    (newFilters: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...newFilters
      }));
    },
    [setClientFilters]
  );

  const resetFilters = useCallback(
    (values: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...values
      }));
    },
    [setClientFilters]
  );

  return {
    clientFilters,
    updateFilters: handleFiltersChange,
    resetFilters
  };
};
