import { map, find, isEmpty, negate } from 'lodash';

import { Task, TaskDTO, TaskTemplateDTO, TaskTemplate } from '@types';

import { Label, Subtask } from '@state/reducers/project/Kanban/type';
import { extractIds } from '@utils';
import { convertFromTemplateMemberIdToPropertyId } from '@utils/properties';
import { PrivilegedTask } from '@generated/types/graphql';

export const taskToFormLabel = (labels: Label[]) =>
  map(labels, (label) => ({
    color: label.color,
    label: label.label,
    labelName: label.label,
    id: label.id
  }));

export const taskToFormSubTask = (subTasks: Subtask[] = []): Subtask[] =>
  map(subTasks, (task, index) => ({
    ...task,
    position: task.position || index + 1
  })).sort((a, b) => a.position - b.position);

export const taskToFormDataAdapter = (task: Task | TaskTemplate): Task => {
  const { templateUsers, ...rest } = task as TaskTemplate;
  const { isTemplate } = task as TaskTemplateDTO;
  const assignees = isTemplate
    ? templateUsers
    : map((task as PrivilegedTask).assigneesByTaskId, (assignee) => assignee.user);

  let type;

  if (isTemplate) {
    type = task.isField ? 'field' : 'office';
  }

  return {
    ...rest,
    assignees,
    subTasks: taskToFormSubTask(task.subTasks),
    labels: taskToFormLabel(task.labels),
    visits: isTemplate ? [] : (task as PrivilegedTask).taskVisitsByTaskIdConnection?.nodes ?? [],
    type,
    startDate: task.startDate ? new Date(task.startDate) : task.startDate, // created from template gives 1970 as date,
    endDate: task.endDate ? new Date(task.endDate) : task.endDate // for that case we want date be empty
  };
};

export const formToTaskDTO = (form: Task | TaskTemplate) => {
  const base = {
    id: form.id,
    projectId: form.projectId,
    title: form.title,
    description: form.description,
    priority: form.priority,
    labels: extractIds(form.labels),
    subTasks: map(form.subTasks, (subtask) => ({
      id: subtask.id,
      detail: subtask.detail,
      isCompleted: subtask.isCompleted,
      position: subtask.position
    })),
    forms: form.forms,
    isCompleted: form.isCompleted,
    isColored: form.isColored,
    templateTaskId: form.templateTaskId,
    isField: form.isField
  };

  if ((form as TaskTemplate).isTemplate) {
    const ids = extractIds(find([form.assignees, form.templateUsers], negate(isEmpty)));

    return {
      ...base,
      endDateXDaysAfter: form.endDateXDaysAfter >= 1 ? form.endDateXDaysAfter : null,
      isField: form.type === 'field',
      isTemplate: form.isTemplate,
      assigneeId: form.assignee?.id > 0 ? form.assignee?.id : null,
      templateAssigneePropertyId:
        form.assignee?.id < 0 ? convertFromTemplateMemberIdToPropertyId(form.assignee?.id) : null,
      templateUsers: ids.filter((id) => id > 0),
      templatePropertyIds: ids.filter((id) => id < 0).map(convertFromTemplateMemberIdToPropertyId)
    } as unknown as TaskTemplateDTO;
  }

  return {
    ...base,
    // null is only valid for unspecified values
    startDate: form.startDate || null,
    startDateAllDay: form.startDateAllDay,
    endDateAllDay: form.endDateAllDay,
    endDate: form.endDate || null,
    assigneeId: form.assignee?.id ?? form.assignee,
    assignees: extractIds(form.assignees),
    visits: form.visits
  } as unknown as TaskDTO;
};
