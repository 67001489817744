import * as Yup from 'yup';

import { isInvalidString } from '@utils';

const getTaskUpdateCompletionRule = () => ({
  subTasks: Yup.array().when(['isCompleted'], {
    is: (isCompleted: boolean) => !!isCompleted,
    then: Yup.array()
      .compact(({ isCompleted }) => isCompleted)
      .length(0, 'Incomplete subtask(s) left')
  })
});

export const taskUpdate = () =>
  Yup.object({
    title: Yup.string()
      .required('Title is required!')
      .test('empty-check', "Title task can't be blank", (value) => !isInvalidString(value)),
    description: Yup.string().nullable(true),
    project: Yup.object().nullable().shape({
      id: Yup.number().moreThan(0, 'Project is required!')
    }),
  }).shape(getTaskUpdateCompletionRule());
