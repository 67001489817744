export const propertiesToMap = [
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Primary Name',
    auroraEntity: 'Project',
    name: 'primaryName',
    defaultValue: ['project.customer_first_name', 'project.customer_last_name'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Primary Email',
    auroraEntity: 'Project',
    name: 'primaryEmail',
    defaultValue: ['project.customer_email'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Primary Phone',
    auroraEntity: 'Project',
    name: 'primaryPhone',
    defaultValue: ['project.customer_phone'],
    isDisabled: true
  },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'ID',
  //   auroraEntity: 'Project',
  //   name: 'id',
  //   defaultValue: [''
  // },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Title',
    auroraEntity: 'Project',
    name: 'title',
    defaultValue: ['project.customer_first_name', 'project.customer_last_name'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Address',
    auroraEntity: 'Project',
    name: 'address',
    defaultValue: ['project.location.property_address'],
    isDisabled: true
  },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Workflow',
  //   auroraEntity: 'Project',
  //   name: 'workflow',
  //   defaultValue: [''
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Stage',
  //   auroraEntity: 'Project',
  //   name: 'stage',
  //   defaultValue: [''
  // },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Project size',
    auroraEntity: 'Project',
    name: 'projectsize',
    defaultValue: ['design.system_size_stc'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Project value',
    auroraEntity: 'Project',
    name: 'projectvalue',
    defaultValue: ['pricing.system_price'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'AHJ',
    auroraEntity: 'Project',
    name: 'aHJ',
    defaultValue: ['ahj.name'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Utility Company',
    auroraEntity: 'Project',
    name: 'utilityCompany',
    defaultValue: ['consumption.utility'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Utility Rate',
    auroraEntity: 'Project',
    name: 'utilityRate',
    defaultValue: ['consumption.utility_rate'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Utility Bill Monthly ($)',
    auroraEntity: 'Project',
    name: 'utilityBillMonthly',
    defaultValue: ['consumption.monthly_bill'],
    isDisabled: true
  },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Ownership Type',
  //   auroraEntity: 'Project',
  //   name: 'ownershipType',
  //   defaultValue: ['projectOwner.email'],
  //   isDisabled: true
  // },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Owner',
    auroraEntity: 'Project',
    name: 'projectOwner',
    defaultValue: ['project.owner'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Financing Provider',
    auroraEntity: 'Project',
    name: 'financingProvider',
    defaultValue: ['financing.financier.provider'],
    isDisabled: true
  },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Financing Monthly Payment ($)',
    auroraEntity: 'Project',
    name: 'financingMonthlyPayment',
    defaultValue: ['financing.monthly_payment'],
    isDisabled: true
  },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Origination Fee',
  //   auroraEntity: 'Project',
  //   name: 'originationFee',
  //   defaultValue: [''
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Gross PPW ($)',
  //   auroraEntity: 'Project',
  //   name: 'grossPPW($)',
  //   defaultValue: [''
  // },
  {
    coperniqEntity: 'Project',
    fieldLabel: 'Net PPW ($)',
    auroraEntity: 'Project',
    name: 'netPPW',
    defaultValue: ['financing.epc_price_per_watt'],
    isDisabled: true
  }
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Contract Signed',
  //   auroraEntity: 'Project',
  //   name: 'contractSigned',
  //   defaultValue: [''
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Project Source',
  //   auroraEntity: 'Project',
  //   name: 'project_source',
  //   defaultValue: [''
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'System Size (kW DC)',
  //   auroraEntity: 'Project',
  //   name: 'systemSizekWDC',
  //   defaultValue: ['design.system_size_stc'],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'System Size (kW AC)',
  //   auroraEntity: 'Project',
  //   name: 'systemSizekWAC',
  //   defaultValue: ['design.system_size_ac'],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Module Model',
  //   auroraEntity: 'Project',
  //   name: 'moduleModel',
  //   defaultValue: ['design.arrays.[0].module.name'],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Module Qty',
  //   auroraEntity: 'Project',
  //   name: 'moduleQty',
  //   defaultValue: ['design.arrays.[0].module.count'],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Inverter Model',
  //   auroraEntity: 'Project',
  //   name: 'inverterModel',
  //   defaultValue: ['design.arrays.[0].microinverter.name'],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Inverter Qty',
  //   auroraEntity: 'Project',
  //   name: 'inverterQty',
  //   defaultValue: ['design.arrays.[0].microinverter.count'],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Battery Model',
  //   auroraEntity: 'Project',
  //   name: 'batteryModel',
  //   defaultValue: ['design.arrays[0].battery.name'], // check
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Project',
  //   fieldLabel: 'Battery Qty',
  //   auroraEntity: 'Project',
  //   name: 'batteryQty',
  //   defaultValue: ['design.arrays[0].battery.count'], // check
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'BOM',
  //   fieldLabel: 'Module',
  //   auroraEntity: 'Project',
  //   name: 'module',
  //   defaultValue: ['design.bill_of_materials.[0].sku'],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'BOM',
  //   fieldLabel: 'Inverter',
  //   auroraEntity: 'Project',
  //   name: 'inverter',
  //   defaultValue: ['design.bill_of_materials.[0].sku'],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'BOM',
  //   fieldLabel: 'Batteries',
  //   auroraEntity: 'Project',
  //   name: 'batteries',
  //   defaultValue: ['design.bill_of_materials.[0].sku'],
  //   isDisabled: true
  // },
  // {
  //   inputText: 'user',
  //   coperniqEntity: 'User',
  //   fieldLabel: 'Sales Rep Email',
  //   auroraEntity: 'Project',
  //   name: 'salesRep',
  //   defaultValue: [''],
  //   isDisabled: true
  // },
  // {
  //   inputText: 'user',
  //   coperniqEntity: 'User',
  //   fieldLabel: 'Project Manager Email',
  //   auroraEntity: 'Project',
  //   name: 'projectManager',
  //   defaultValue: [''],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'User',
  //   fieldLabel: 'Owner Email',
  //   auroraEntity: 'Project',
  //   name: 'owner',
  //   defaultValue: ['project.customer_email'],
  //   isDisabled: true
  // }
  // {
  //   coperniqEntity: 'Doc',
  //   fieldLabel: 'Utility Bill PDF',
  //   auroraEntity: 'Project',
  //   name: 'utilityBillPDF',
  //   defaultValue: [''],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Doc',
  //   fieldLabel: 'Contract PDF',
  //   auroraEntity: 'Project',
  //   name: 'contractPDF',
  //   defaultValue: [''],
  //   isDisabled: true
  // },
  // {
  //   coperniqEntity: 'Doc',
  //   fieldLabel: 'Financing Document PDF',
  //   auroraEntity: 'Project',
  //   name: 'financingDocumentPDF',
  //   defaultValue: [''],
  //   isDisabled: true
  // }
];

export const auroraPropertyOptions = [
  {
    label: 'Project > Owner',
    value: 'project.owner'
  },
  {
    label: 'Project > Id',
    value: 'project.id'
  },
  {
    label: 'Project > External provider id',
    value: 'project.external_provider_id'
  },
  {
    label: 'Project > Created from lead id',
    value: 'project.created_from_lead_id'
  },
  {
    label: 'Project > Name',
    value: 'project.name'
  },
  {
    label: 'Project > Customer salutation',
    value: 'project.customer_salutation'
  },
  {
    label: 'Project > Customer first name',
    value: 'project.customer_first_name'
  },
  {
    label: 'Project > Customer last name',
    value: 'project.customer_last_name'
  },
  {
    label: 'Project > Mailing address',
    value: 'project.mailing_address'
  },
  {
    label: 'Project > Customer email',
    value: 'project.customer_email'
  },
  {
    label: 'Project > Customer phone',
    value: 'project.customer_phone'
  },
  {
    label: 'Project > Location > Latitude',
    value: 'project.location.latitude'
  },
  {
    label: 'Project > Location > Longitude',
    value: 'project.location.longitude'
  },
  {
    label: 'Project > Location > Property address',
    value: 'project.location.property_address'
  },
  {
    label: 'Project > Location > Property address components > Street address',
    value: 'project.location.property_address_components.street_address'
  },
  {
    label: 'Project > Location > Property address components > City',
    value: 'project.location.property_address_components.city'
  },
  {
    label: 'Project > Location > Property address components > Region',
    value: 'project.location.property_address_components.region'
  },
  {
    label: 'Project > Location > Property address components > Postal code',
    value: 'project.location.property_address_components.postal_code'
  },
  {
    label: 'Project > Location > Property address components > Country',
    value: 'project.location.property_address_components.country'
  },
  {
    label: 'Project > Project type',
    value: 'project.project_type'
  },
  {
    label: 'Project > Owner id',
    value: 'project.owner_id'
  },
  {
    label: 'Project > Team id',
    value: 'project.team_id'
  },
  {
    label: 'Project > Status',
    value: 'project.status'
  },
  {
    label: 'Project > Ahj id',
    value: 'project.ahj_id'
  },
  {
    label: 'Project > Preferred solar modules',
    value: 'project.preferred_solar_modules'
  },
  {
    label: 'Project > Partner id',
    value: 'project.partner_id'
  },
  {
    label: 'Project > Tags',
    value: 'project.tags'
  },
  {
    label: 'Project > Created at',
    value: 'project.created_at'
  },
  {
    label: 'Design > Design id',
    value: 'design.design_id'
  },
  {
    label: 'Design > Design request id',
    value: 'design.design_request_id'
  },
  {
    label: 'Design > External provider id',
    value: 'design.external_provider_id'
  },
  {
    label: 'Design > Project id',
    value: 'design.project_id'
  },
  {
    label: 'Design > Bill of materials > [0] > Id',
    value: 'design.bill_of_materials.[0].id'
  },
  {
    label: 'Design > Bill of materials > [0] > Component type',
    value: 'design.bill_of_materials.[0].component_type'
  },
  {
    label: 'Design > Bill of materials > [0] > Sku',
    value: 'design.bill_of_materials.[0].sku'
  },
  {
    label: 'Design > Bill of materials > [0] > Name',
    value: 'design.bill_of_materials.[0].name'
  },
  {
    label: 'Design > Bill of materials > [0] > Manufacturer name',
    value: 'design.bill_of_materials.[0].manufacturer_name'
  },
  {
    label: 'Design > Bill of materials > [0] > Quantity',
    value: 'design.bill_of_materials.[0].quantity'
  },
  {
    label: 'Design > Arrays > [0] > Module > Id',
    value: 'design.arrays.[0].module.id'
  },
  {
    label: 'Design > Arrays > [0] > Module > Name',
    value: 'design.arrays.[0].module.name'
  },
  {
    label: 'Design > Arrays > [0] > Module > Rating stc',
    value: 'design.arrays.[0].module.rating_stc'
  },
  {
    label: 'Design > Arrays > [0] > Module > Orientation',
    value: 'design.arrays.[0].module.orientation'
  },
  {
    label: 'Design > Arrays > [0] > Module > Count',
    value: 'design.arrays.[0].module.count'
  },
  {
    label: 'Design > Arrays > [0] > Microinverter > Name', // check
    value: 'design.arrays.[0].microinverter.name'
  },
  {
    label: 'Design > Arrays > [0] > Microinverter > Count', // check
    value: 'design.arrays.[0].microinverter.count'
  },
  {
    label: 'Design > Arrays > [0] > Azimuth',
    value: 'design.arrays.[0].azimuth'
  },
  {
    label: 'Design > Arrays > [0] > Pitch',
    value: 'design.arrays.[0].pitch'
  },
  {
    label: 'Design > Arrays > [0] > Face',
    value: 'design.arrays.[0].face'
  },
  {
    label: 'Design > Arrays > [0] > Configuration',
    value: 'design.arrays.[0].configuration'
  },
  {
    label: 'Design > Arrays > [0] > Shading > Solar access > Annual',
    value: 'design.arrays.[0].shading.solar_access.annual'
  },
  {
    label: 'Design > Arrays > [0] > Shading > Total solar resource fraction > Annual',
    value: 'design.arrays.[0].shading.total_solar_resource_fraction.annual'
  },
  {
    label: 'Design > Arrays > [0] > Size',
    value: 'design.arrays.[0].size'
  },
  {
    label: 'Design > Arrays > [0] > Ground mounted',
    value: 'design.arrays.[0].ground_mounted'
  },
  {
    label: 'Design > String inverters',
    value: 'design.string_inverters'
  },
  {
    label: 'Design > Energy production > Up to date',
    value: 'design.energy_production.up_to_date'
  },
  {
    label: 'Design > Energy production > Annual',
    value: 'design.energy_production.annual'
  },
  {
    label: 'Design > Energy production > Annual offset',
    value: 'design.energy_production.annual_offset'
  },
  {
    label: 'Design > Batteries',
    value: 'design.batteries'
  },
  {
    label: 'Design > Storage selected operating mode',
    value: 'design.storage_selected_operating_mode'
  },
  {
    label: 'Design > Storage inverters',
    value: 'design.storage_inverters'
  },
  {
    label: 'Design > System size stc',
    value: 'design.system_size_stc'
  },
  {
    label: 'Design > System size ptc',
    value: 'design.system_size_ptc'
  },
  {
    label: 'Design > System size ac',
    value: 'design.system_size_ac'
  },
  {
    label: 'Design > Created at',
    value: 'design.created_at'
  },
  {
    label: 'Pricing > Pricing method',
    value: 'pricing.pricing_method'
  },
  {
    label: 'Pricing > Flat system price',
    value: 'pricing.flat_system_price'
  },
  {
    label: 'Pricing > Price per watt',
    value: 'pricing.price_per_watt'
  },
  {
    label: 'Pricing > Storage pricing method',
    value: 'pricing.storage_pricing_method'
  },
  {
    label: 'Pricing > Pricing by component > [0] > Name',
    value: 'pricing.pricing_by_component.[0].name'
  },
  {
    label: 'Pricing > Pricing by component > [0] > Price',
    value: 'pricing.pricing_by_component.[0].price'
  },
  {
    label: 'Pricing > Pricing by component > [0] > Quantity',
    value: 'pricing.pricing_by_component.[0].quantity'
  },
  {
    label: 'Pricing > Pricing by component > [0] > Manufacturer name',
    value: 'pricing.pricing_by_component.[0].manufacturer_name'
  },
  {
    label: 'Pricing > Pricing by component > [0] > Component type',
    value: 'pricing.pricing_by_component.[0].component_type'
  },
  {
    label: 'Pricing > Energy optimization pricing method',
    value: 'pricing.energy_optimization_pricing_method'
  },
  {
    label: 'Pricing > Adders',
    value: 'pricing.adders'
  },
  {
    label: 'Pricing > Incentives > [0] > Name',
    value: 'pricing.incentives.[0].name'
  },
  {
    label: 'Pricing > Incentives > [0] > Amount',
    value: 'pricing.incentives.[0].amount'
  },
  {
    label: 'Pricing > Incentives > [0] > Incentive type',
    value: 'pricing.incentives.[0].incentive_type'
  },
  {
    label: 'Pricing > Incentives > [0] > Disbursement',
    value: 'pricing.incentives.[0].disbursement'
  },
  {
    label: 'Pricing > Incentives > [0] > Duration',
    value: 'pricing.incentives.[0].duration'
  },
  {
    label: 'Pricing > Incentives > [0] > Apply local tax',
    value: 'pricing.incentives.[0].apply_local_tax'
  },
  {
    label: 'Pricing > Incentives > [0] > Apply national tax',
    value: 'pricing.incentives.[0].apply_national_tax'
  },
  {
    label: 'Pricing > Incentives > [0] > System size basis',
    value: 'pricing.incentives.[0].system_size_basis'
  },
  {
    label: 'Pricing > Incentives > [0] > Year applied',
    value: 'pricing.incentives.[0].year_applied'
  },
  {
    label: 'Pricing > Incentives > [0] > Incentive scope',
    value: 'pricing.incentives.[0].incentive_scope'
  },
  {
    label: 'Pricing > Incentives > [0] > Cap',
    value: 'pricing.incentives.[0].cap'
  },
  {
    label: 'Pricing > System price breakdown > [0] > Item type',
    value: 'pricing.system_price_breakdown.[0].item_type'
  },
  {
    label: 'Pricing > System price breakdown > [0] > Item price',
    value: 'pricing.system_price_breakdown.[0].item_price'
  },
  {
    label: 'Pricing > System price breakdown > [0] > Cumulative price',
    value: 'pricing.system_price_breakdown.[0].cumulative_price'
  },
  {
    label: 'Pricing > System price breakdown > [0] > Subcomponents',
    value: 'pricing.system_price_breakdown.[0].subcomponents'
  },
  {
    label: 'Pricing > Storage system price breakdown',
    value: 'pricing.storage_system_price_breakdown'
  },
  {
    label: 'Pricing > Energy optimization price breakdown > [0] > Item type',
    value: 'pricing.energy_optimization_price_breakdown.[0].item_type'
  },
  {
    label: 'Pricing > Energy optimization price breakdown > [0] > Item price',
    value: 'pricing.energy_optimization_price_breakdown.[0].item_price'
  },
  {
    label: 'Pricing > Energy optimization price breakdown > [0] > Cumulative price',
    value: 'pricing.energy_optimization_price_breakdown.[0].cumulative_price'
  },
  {
    label: 'Pricing > Energy optimization price breakdown > [0] > Subcomponents',
    value: 'pricing.energy_optimization_price_breakdown.[0].subcomponents'
  },
  {
    label: 'Pricing > System price',
    value: 'pricing.system_price'
  },
  {
    label: 'ProjectOwner > Id',
    value: 'projectOwner.id'
  },
  {
    label: 'ProjectOwner > Email',
    value: 'projectOwner.email'
  },
  {
    label: 'ProjectOwner > Account status',
    value: 'projectOwner.account_status'
  },
  {
    label: 'ProjectOwner > Phone',
    value: 'projectOwner.phone'
  },
  {
    label: 'ProjectOwner > First name',
    value: 'projectOwner.first_name'
  },
  {
    label: 'ProjectOwner > Last name',
    value: 'projectOwner.last_name'
  },
  {
    label: 'ProjectOwner > Locale',
    value: 'projectOwner.locale'
  },
  {
    label: 'ProjectOwner > Title',
    value: 'projectOwner.title'
  },
  {
    label: 'ProjectOwner > Job function',
    value: 'projectOwner.job_function'
  },
  {
    label: 'ProjectOwner > Tenant id',
    value: 'projectOwner.tenant_id'
  },
  {
    label: 'ProjectOwner > External provider id',
    value: 'projectOwner.external_provider_id'
  },
  {
    label: 'ProjectOwner > Role id',
    value: 'projectOwner.role_id'
  },
  {
    label: 'ProjectOwner > Team ids',
    value: 'projectOwner.team_ids'
  },
  {
    label: 'ProjectOwner > Partner id',
    value: 'projectOwner.partner_id'
  },
  {
    label: 'ProjectOwner > Partner ids',
    value: 'projectOwner.partner_ids'
  },
  {
    label: 'ProjectOwner > Base price per watt max',
    value: 'projectOwner.base_price_per_watt_max'
  },
  {
    label: 'ProjectOwner > Base price per watt min',
    value: 'projectOwner.base_price_per_watt_min'
  },
  {
    label: 'Financing > Id',
    value: 'financing.id'
  },
  {
    label: 'Financing > Project id',
    value: 'financing.project_id'
  },
  {
    label: 'Financing > Design id',
    value: 'financing.design_id'
  },
  {
    label: 'Financing > Name',
    value: 'financing.name'
  },
  {
    label: 'Financing > Created at',
    value: 'financing.created_at'
  },
  {
    label: 'Financing > Ran at',
    value: 'financing.ran_at'
  },
  {
    label: 'Financing > Up to date',
    value: 'financing.up_to_date'
  },
  {
    label: 'Financing > Financing option',
    value: 'financing.financing_option'
  },
  {
    label: 'Financing > System price',
    value: 'financing.system_price'
  },
  {
    label: 'Financing > Cashflow discount rate',
    value: 'financing.cashflow_discount_rate'
  },
  {
    label: 'Financing > Project useful life',
    value: 'financing.project_useful_life'
  },
  {
    label: 'Financing > Degradation factor',
    value: 'financing.degradation_factor'
  },
  {
    label: 'Financing > Inverter price per watt',
    value: 'financing.inverter_price_per_watt'
  },
  {
    label: 'Financing > Inverter life',
    value: 'financing.inverter_life'
  },
  {
    label: 'Financing > Lifetime savings',
    value: 'financing.lifetime_savings'
  },
  {
    label: 'Financing > Incentive values',
    value: 'financing.incentive_values'
  },
  {
    label: 'Financing > Selected in sales mode',
    value: 'financing.selected_in_sales_mode'
  },
  {
    label: 'Financing > Payback period',
    value: 'financing.payback_period'
  },
  {
    label: 'Financing > Loan principal',
    value: 'financing.loan_principal'
  },
  {
    label: 'Financing > Down payment',
    value: 'financing.down_payment'
  },
  {
    label: 'Financing > Avg monthly savings',
    value: 'financing.avg_monthly_savings'
  },
  {
    label: 'Financing > Monthly payment first month',
    value: 'financing.monthly_payment_first_month'
  },
  {
    label: 'Financing > Loans',
    value: 'financing.loans'
  },
  {
    label: 'Financing > Financier > Provider',
    value: 'financing.financier.provider'
  },
  {
    label: 'Financing > Financier > Status',
    value: 'financing.financier.status'
  },
  {
    label: 'Financing > Financier > Approved loan amount',
    value: 'financing.financier.approved_loan_amount'
  },
  {
    label: 'Financing > Financier > External > Provider status',
    value: 'financing.financier.external.provider_status'
  },
  {
    label: 'Financing > Financier > External > Request id',
    value: 'financing.financier.external.request_id'
  },
  {
    label: 'Financing > Financier > External > Consumer id',
    value: 'financing.financier.external.consumer_id'
  },
  {
    label: 'Financing > Financier > External > Quote id',
    value: 'financing.financier.external.quote_id'
  },
  {
    label: 'Financing > Financier > Created at',
    value: 'financing.financier.created_at'
  },
  {
    label: 'Financing > Financier > Updated at',
    value: 'financing.financier.updated_at'
  },
  {
    label: 'Financing > Epc price per watt',
    value: 'financing.epc_price_per_watt'
  },
  {
    label: 'Financing > Escalation',
    value: 'financing.escalation'
  },
  {
    label: 'Financing > Monthly payment',
    value: 'financing.monthly_payment'
  },
  {
    label: 'Financing > Solar rate',
    value: 'financing.solar_rate'
  },
  {
    label: 'Financing > Specific yield',
    value: 'financing.specific_yield'
  },
  {
    label: 'Financing > Upfront payment',
    value: 'financing.upfront_payment'
  },
  {
    label: 'Consumption > Id',
    value: 'consumption.id'
  },
  {
    label: 'Consumption > Project id',
    value: 'consumption.project_id'
  },
  {
    label: 'Consumption > Location',
    value: 'consumption.location'
  },
  {
    label: 'Consumption > Utility id',
    value: 'consumption.utility_id'
  },
  {
    label: 'Consumption > Utility rate id',
    value: 'consumption.utility_rate_id'
  },
  {
    label: 'Consumption > Utility rate version id',
    value: 'consumption.utility_rate_version_id'
  },
  {
    label: 'Consumption > Utility',
    value: 'consumption.utility'
  },
  {
    label: 'Consumption > Utility rate',
    value: 'consumption.utility_rate'
  },
  {
    label: 'Consumption > Monthly bill',
    value: 'consumption.monthly_bill'
  },
  {
    label: 'Consumption > Utility rate version',
    value: 'consumption.utility_rate_version'
  },
  {
    label: 'Consumption > Input mode',
    value: 'consumption.input_mode'
  },
  {
    label: 'Consumption > Efficiency packages',
    value: 'consumption.efficiency_packages'
  },
  {
    label: 'Ahj > Id',
    value: 'ahj.id'
  },
  {
    label: 'Ahj > Name',
    value: 'ahj.name'
  },
  {
    label: 'Ahj > Units of measurement',
    value: 'ahj.units_of_measurement'
  },
  {
    label: 'Ahj > General requirements > Wind code',
    value: 'ahj.general_requirements.wind_code'
  },
  {
    label: 'Ahj > Structural requirements > Wind speed',
    value: 'ahj.structural_requirements.wind_speed'
  },
  {
    label: 'Ahj > Structural requirements > Snow load',
    value: 'ahj.structural_requirements.snow_load'
  },
  {
    label: 'Ahj > Structural requirements > Max span',
    value: 'ahj.structural_requirements.max_span'
  },
  {
    label: 'Agreement > Id',
    value: 'agreement.id'
  },
  {
    label: 'Agreement > Project id',
    value: 'agreement.project_id'
  },
  {
    label: 'Agreement > Design id',
    value: 'agreement.design_id'
  },
  {
    label: 'Agreement > Status',
    value: 'agreement.status'
  },
  {
    label: 'Agreement > Last viewed at',
    value: 'agreement.last_viewed_at'
  },
  {
    label: 'Agreement > Error message',
    value: 'agreement.error_message'
  },
  {
    label: 'Agreement > Created at',
    value: 'agreement.created_at'
  },
  {
    label: 'Agreement > Sent at',
    value: 'agreement.sent_at'
  },
  {
    label: 'Agreement > Agreement template name',
    value: 'agreement.agreement_template_name'
  },
  {
    label: 'Partners',
    value: 'partners'
  },
  {
    label: 'Documents > [0] > File url',
    value: 'documents.[0].file_url'
  },
  {
    label: 'Documents > [0] > Expires at',
    value: 'documents.[0].expires_at'
  },
  {
    label: 'Documents > [0] > Name',
    value: 'documents.[0].name'
  }
];
