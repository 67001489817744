import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loader from '@common/Loader';
import { useSelectionWithGroups } from '@features/SystemPortfolio/List/Table/useSelection';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { COLUMNS, renderColumnPlain } from '@features/SystemPortfolio/constants';
import { useCounter } from 'react-use';
import { SystemColumn, SystemWithStats, useSystems } from '@hooks/systems';
import { useAppSelector } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { SystemStatus } from '@features/SystemPortfolio/components';
import { useShowMap } from '@features/SystemPortfolio/List/Table/useShowMap';
import { columnStyleWithMap, columnStyleWithoutMap } from '@features/SystemPortfolio/List/Table/styled';
import { SystemEnergyAverageChart } from '@features/SystemPortfolio/components/SystemEnergyAverageChart';
import { useFlattenSystemsProductionCharts } from '@hooks/systems/useFlattenSystemsWeekProductionCharts';
import { useClientFilterState } from '../../useClientFilterState';
import { TitleCell } from './TitleCell';
import { Container, LoaderWrapper, LoadMore, Row } from './styled';

interface Props {
  ids: number[];
  firstPageData: SystemWithStats[];
  groupLabel: string | null;
}

export const TableBody = ({ ids = [], firstPageData = [], groupLabel }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const [systems, setSystems] = useState<SystemWithStats[]>(firstPageData);
  const {
    clientFilters: { perPage },
    clientFilters
  } = useClientFilterState();

  const [currentPage, { inc: loadNextPage, reset: resetPage }] = useCounter(1);

  const paginatedIds = useMemo(() => {
    const currentPageIndex = currentPage - 1;
    const firstIndex = currentPageIndex * perPage.value;
    const lastIndex = firstIndex + perPage.value;

    return ids.slice(firstIndex, lastIndex);
  }, [ids, currentPage, perPage.value]);

  const allSystemIds = useMemo(() => systems.map(({ id }) => id), [systems]);
  const {
    data: systemsProductionChartById,
    totalPages: totalChartPages,
    isFetching: isFetchingChart,
    fetchNextPage: fetchNextPageChart
  } = useFlattenSystemsProductionCharts(allSystemIds, perPage.value);

  const { data: nextPage, isFetching: isFetchingNextPage } = useSystems(
    {
      filter: {
        id: { in: paginatedIds },
        integration: { companyId: { equalTo: companyId } }
      }
    },
    {
      enabled: currentPage > 1
    }
  );

  useEffect(() => setSystems((prevSystems) => [...prevSystems, ...nextPage]), [setSystems, nextPage]);

  // useEffect because current pagination for useSystems is not using useInfiniteQuery and chart is
  useEffect(() => {
    const isChartPagesOutdated = currentPage > 1 && currentPage > totalChartPages;
    const shouldFetchMoreChartData = isChartPagesOutdated && allSystemIds.length > (currentPage - 1) * perPage.value;

    if (shouldFetchMoreChartData) {
      fetchNextPageChart();
    }
  }, [currentPage, totalChartPages, systems, allSystemIds, fetchNextPageChart, perPage.value]);

  useEffect(() => {
    setSystems(firstPageData);
    resetPage();
  }, [firstPageData, clientFilters, setSystems, resetPage]);

  const { setDataForGroup, isItemSelected } = useSelectionWithGroups();
  useEffect(() => setDataForGroup(groupLabel, systems), [systems, groupLabel, setDataForGroup]);

  const { openDrawer } = useDrawersContext();
  const handleRowClick = useCallback(
    (id: number) => {
      openDrawer(DrawerEntity.SYSTEM, id, ids);
    },
    [openDrawer, ids]
  );

  const [showMap] = useShowMap();
  const columnStyle = useMemo(() => (showMap ? columnStyleWithMap : columnStyleWithoutMap), [showMap]);

  return (
    <Container>
      <tbody>
        {systems.map((system) => (
          <Row
            key={system.id}
            isSelected={isItemSelected(groupLabel, system)}
            isDimmed={!system.monitored}
            onClick={() => handleRowClick(system.id)}
          >
            {COLUMNS.map((column) => {
              switch (column.id) {
                case SystemColumn.name:
                  return (
                    <TitleCell key={column.id} system={system} groupLabel={groupLabel} style={columnStyle[column.id]} />
                  );

                case SystemColumn.status:
                  return (
                    <td key={column.id} style={columnStyle[column.id]}>
                      <SystemStatus system={system} vertical />
                    </td>
                  );

                case SystemColumn.sevenDayAverage: {
                  const chartData = systemsProductionChartById[system.uuid];

                  return (
                    <td key={column.id} style={columnStyle[column.id]}>
                      <SystemEnergyAverageChart monitored={system.monitored} points={chartData?.points ?? []} />
                    </td>
                  );
                }

                default:
                  return (
                    <td key={column.id} style={columnStyle[column.id]}>
                      <span>{renderColumnPlain(system, column.id)}</span>
                    </td>
                  );
              }
            })}
          </Row>
        ))}
      </tbody>

      {ids.length > systems.length && (
        <tfoot>
          <tr>
            <td>
              {(isFetchingNextPage || isFetchingChart) && (
                <LoaderWrapper>
                  <Loader size={24} />
                </LoaderWrapper>
              )}

              {!isFetchingNextPage && !isFetchingChart && (
                <LoadMore onClick={() => loadNextPage()}>Load more ({ids.length - systems.length})</LoadMore>
              )}
            </td>
            <td colSpan={COLUMNS.length - 1} />
          </tr>
        </tfoot>
      )}
    </Container>
  );
};
