import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #f7f7fc;
`;

export const Sidebar = styled.div`
  width: 250px;
  flex-shrink: 0;
  height: calc(100vh - 48px);
  overflow: auto;
  ${scroll};

  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-right: 1px solid #dfdfe8;
  font-size: 14px;
  font-weight: 400;
`;

export const Content = styled.div`
  flex: 1;
  padding: 0 16px;
  height: calc(100vh - 48px);
  overflow: auto;
`;

export const NavGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  a {
    display: flex;
    align-items: center;
  }

  a,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    color: #1d1d35;
    text-decoration: none;
  }

  a.active {
    font-weight: 500;
    color: ${colors.green};
  }

  span {
    display: flex;
    align-items: center;
    color: ${colors.gray};
  }
`;

export const NavGroupName = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const ItemWithBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DisabledLink = styled.div`
  display: flex;
  align-items: center;
  color: #c8c8d3;
`;
