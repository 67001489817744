import { device } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 7px 24px;
  background-color: #fff;
  border-bottom: 1px solid #dfdfe8;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  > *:not(:first-child) {
    display: none;
  }

  ${device.md`
    > *:not(:first-child) {
      display: flex;
    }
  `}

  ${device.xxl`
    gap: 24px;
  `}
  

  &&& {
    > * {
      margin: 0;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${device.xl`
  gap: 24px;
  `}
`;
