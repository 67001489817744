import React, { useState } from 'react';
import { useConnectIntegrationHistoryByProject } from '@hooks/workspace/connectIntegration';
import { Users, ExternalLink } from 'react-feather';
import moment from 'moment';
import { getIntegrationName } from '@features/Platform/Integrations';
import { colors } from '@styles';
import { Badge } from '@kit/ui/Badge';
import { Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';
import { Tabs, Tab, List, Row, Cell, CellBotton, CellTop } from './styled';
import Aurora from '../../../../../assets/svg/aurora2.svg';

interface Props {
  projectId: number;
  isFullWidth?: boolean;
  title?: string;
}

const TABS = [
  {
    id: 'projects',
    title: 'PROJECTS'
  },
  {
    id: 'proposals',
    title: 'PROPOSALS'
  },
  {
    id: 'contracts',
    title: 'CONTRACTS'
  }
];

export const Sales: React.FC<Props> = ({ title = 'Sales', projectId, isFullWidth = false }) => {
  const [activeTab, setActiveTab] = useState('projects');
  const { data: logs } = useConnectIntegrationHistoryByProject({
    projectId
  });

  return (
    <Widget isFullWidth={isFullWidth}>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#ECE3FF">
            <Users size="16px" color="#7256AC" />
          </WidgetIcon>
          {title}
        </WidgetTitle>
      </WidgetHeader>

      <Tabs>
        {TABS.map((tab) => (
          <Tab key={tab.id} onClick={() => setActiveTab(tab.id)} isActive={activeTab === tab.id}>
            {tab.title}
          </Tab>
        ))}
      </Tabs>

      {activeTab === 'projects' && (
        <List>
          {logs?.map((log) => (
            <Row key={log.id}>
              <Cell>
                <div>{log.coperniqProject.title}</div>
                <CellBotton>{moment(log.createdAt).format('MM/DD/YYYY h:mma')}</CellBotton>
              </Cell>
              <Cell>
                <Badge color="#4D7CFE">{log.sourceStatus ? log.sourceStatus : '----'}</Badge>
                <div>{`${getIntegrationName(log.connectIntegration.type)} status`}</div>
              </Cell>
              <Cell>
                <CellTop>
                  <img src={Aurora} width={20} height={20} alt="Aurora" />
                  {getIntegrationName(log.connectIntegration.type)}
                  <a
                    href={`https://v2.aurorasolar.com/projects/${log.sourceProjectId}/overview/dashboard`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLink size={16} color={colors.blue} />
                  </a>
                </CellTop>
                <CellBotton>ID: {log.sourceProjectId.slice(0, 5)}</CellBotton>
              </Cell>
            </Row>
          ))}
        </List>
      )}

      {activeTab === 'proposals' && (
        <List>
          {logs?.map((log) => (
            <Row key={log.id}>
              <Cell>
                <div>{log.projectName}</div>
                <CellBotton>{moment(log.createdAt).format('MM/DD/YYYY h:mma')}</CellBotton>
              </Cell>
              <Cell>
                <Badge color="#4D7CFE">{log.sourceStatus ? log.sourceStatus : '----'}</Badge>
                <div>{`${getIntegrationName(log.connectIntegration.type)} status`}</div>
              </Cell>
              <Cell>
                <CellTop>
                  <img src={Aurora} width={20} height={20} alt="Aurora" />
                  {getIntegrationName(log.connectIntegration.type)}
                  <a
                    href={`https://v2.aurorasolar.com/projects/${log.sourceProjectId}/overview/dashboard`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLink size={16} color={colors.blue} />
                  </a>
                </CellTop>
                <CellBotton>ID: {log.sourceProjectId?.slice(0, 5)}</CellBotton>
              </Cell>
            </Row>
          ))}
        </List>
      )}
      {activeTab === 'contracts' && (
        <List>
          {logs?.map((log) => (
            <Row key={log.id}>
              <Cell>
                <div>{log.agreementName}</div>
                <CellBotton>{moment(log.createdAt).format('MM/DD/YYYY h:mma')}</CellBotton>
              </Cell>
              <Cell>
                <Badge color="#4D7CFE">{log.sourceStatus ? log.sourceStatus : '----'}</Badge>
                <div>{`${getIntegrationName(log.connectIntegration.type)} status`}</div>
              </Cell>
              <Cell>
                <CellTop>
                  <img src={Aurora} width={20} height={20} alt="Aurora" />
                  {getIntegrationName(log.connectIntegration.type)}
                  <a
                    href={`https://v2.aurorasolar.com/projects/${log.sourceProjectId}/overview/dashboard`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExternalLink size={16} color={colors.blue} />
                  </a>
                </CellTop>
                <CellBotton>ID: {log.sourceProjectId.slice(0, 5)}</CellBotton>
              </Cell>
            </Row>
          ))}
        </List>
      )}
    </Widget>
  );
};
