import React, { useMemo, useCallback, useState } from 'react';
import { ChevronDown, ChevronRight, X, Save, Edit, ExternalLink } from 'react-feather';
import { useConnectIntegrationHistoryByProject } from '@hooks/workspace/connectIntegration';
import { colors } from '@styles';
import Collapse from '@material-ui/core/Collapse';
import { useSet } from 'react-use';
import { CatalogType } from '@types';
import { THead, TRow, TCell, ThCell, TBody } from '@components/templates/Workspace/common/styled';
import { StateMessege, RichEditorReactForm, FacebookCircularProgress } from '@common';
import { Table, Grid, Box } from '@material-ui/core';
import { getIntegrationName } from '@features/Platform/Integrations';
import { useRoutes, useLineItem, useToast, useLineItemsMutation, useAppSelector } from '@hooks';
import { SearchBar } from '@common/SearchBar';
import { Header } from '@components/Project/Properties/styled';
import { debounce } from 'lodash/fp';
import { get, lowerCase, capitalize } from 'lodash';
import { TextField, TextFieldProps } from '@common/ui';
import { Remove } from '@components/templates/Workspace/common/Actions/styled';
import { CatalogItemSelector } from '@common/Selector/CatalogItemSelector/CatalogItemSelector';
import { useFieldArray, useForm, FormProvider, Controller, useWatch } from 'react-hook-form';
import { LineItem } from '@generated/types/graphql';
import { UiStates } from '@enums';
import { formatMoney, hasAccess } from '@utils';
import { selectCurrentUser } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import styled from 'styled-components';
import { Button, ButtonVariant } from '@kit/ui/Button';
import Aurora from '../../../assets/svg/aurora2.svg';
import {
  GroupHeader,
  GroupHeaderTitle,
  IconWrapper,
  GroupBody,
  BodyWrapper,
  Buttons,
  AnalyticCell,
  AnalyticHeaderValue,
  AnalyticHeaderName,
  AnalyticBarName,
  AnalyticBarValue,
  AnalyticsContainer,
  AnalyticTable,
  StyledLinearProgress,
  Wrapper
} from './styled';

const NumericField = ({ label, name }: Pick<TextFieldProps, 'value' | 'onChange' | 'label' | 'name'>) => (
  <Controller
    name={name}
    render={({ field, fieldState: { error } }) => (
      <TextField
        {...field}
        type="number"
        placeholder={`Enter ${lowerCase(label)}`}
        variant="outlined"
        required
        error={!!error}
        helperText={error?.message}
        helperState={error ? UiStates.ERROR : UiStates.DEFAULT}
        InputProps={{ inputProps: { min: 0 } }}
      />
    )}
  />
);

type Props = {
  recordId: number;
};

export const Financial: React.FC = ({ recordId }: Props) => {
  const [, collapsedTypes] = useSet(new Set<CatalogType>());
  const { companyId } = useRoutes();
  const { data: rawData } = useLineItem(recordId);
  const { data: logs } = useConnectIntegrationHistoryByProject({
    projectId: recordId
  });
  const types = useMemo(() => Object.values(CatalogType), []);
  const [searchValue, setSearchValue] = useState('');
  const [editMode, setEditMode] = useState(false);
  const user = useAppSelector(selectCurrentUser);
  const { data: access } = useUserRoleSettings(companyId, user.userId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChange = useCallback(debounce(300, setSearchValue), []);
  const result = useMemo(
    () =>
      searchValue.trim()
        ? types.reduce(
            (acc, type) => ({
              ...acc,
              [type]: {
                ...rawData[type],
                items: rawData[type].items.filter((item) =>
                  ['description', 'catalogItem.name', 'catalogItem.sku'].some((path) =>
                    get(item, path)?.toLowerCase()?.includes(searchValue)
                  )
                )
              }
            }),
            rawData
          )
        : rawData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue, rawData]
  );

  const { showError } = useToast();
  const { update: updateLineItems } = useLineItemsMutation();

  const itemsToFormValues = () => ({
    items: types.reduce((acc, type) => [...acc, ...rawData[type].items], [])
  });
  const form = useForm<{ items: LineItem[] }>({
    defaultValues: itemsToFormValues()
  });
  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: 'items'
  });
  const watchedFields = useWatch({
    control: form.control,
    name: 'items'
  });

  const fieldsByType = useMemo(
    () =>
      fields.reduce(
        (acc, field, index) => ({
          ...acc,
          [field.catalogItem.type]: [
            ...acc[field.catalogItem.type as CatalogType],
            {
              ...field,
              index
            }
          ]
        }),
        types.reduce(
          (acc, type) => ({
            ...acc,
            [type]: []
          }),
          {}
        ) as { [key in CatalogType]: (LineItem & { index: number })[] }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields]
  );

  const grandTotal = useMemo(
    () =>
      (editMode ? watchedFields : types.reduce((acc, type) => [...acc, ...result[type].items], [])).reduce(
        (acc, field) => ({
          cost: acc.cost + (editMode ? field.quantity * field.unitCost : field.totalCost),
          price: acc.price + (editMode ? field.quantity * field.unitPrice : field.totalPrice)
        }),
        {
          cost: 0,
          price: 0
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMode, watchedFields, result]
  );

  const handleSubmit = ({ items }: { items: LineItem[] }) =>
    updateLineItems.mutateAsync(
      {
        dto: items.map((item) => ({
          catalogItemId: item.catalogItem.id,
          unitPrice: +item.unitPrice,
          unitCost: +item.unitCost,
          quantity: +item.quantity,
          description: item.description
        })),
        projectId: recordId
      },
      {
        onSuccess: () => {
          setEditMode(!editMode);
          form.reset(itemsToFormValues());
        }
      }
    );

  return (
    <Wrapper>
      <AnalyticsContainer container>
        {[
          {
            valueProperty: 'price',
            marginProperty: 'ofTotalPrice',
            label: 'REVENUE'
          },
          {
            valueProperty: 'cost',
            marginProperty: 'ofTotalCost',
            label: 'COST'
          }
        ].map(({ valueProperty, marginProperty, label }) => (
          <Grid item xs={6} sm={6} md={3} lg={4}>
            <AnalyticCell>
              <AnalyticHeaderValue>{formatMoney(rawData[valueProperty])}</AnalyticHeaderValue>
              <AnalyticHeaderName>{label}</AnalyticHeaderName>
              <div>
                <AnalyticTable>
                  {types.map((type) => {
                    const result = rawData[type];

                    return (
                      <tr>
                        <td>
                          <AnalyticBarName>{capitalize(`${type}s`)}</AnalyticBarName>
                          <AnalyticBarValue>{formatMoney(result[valueProperty])}</AnalyticBarValue>
                        </td>
                        <td colSpan={4} width={100}>
                          <div>
                            <StyledLinearProgress variant="determinate" value={result[marginProperty]} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </AnalyticTable>
              </div>
            </AnalyticCell>
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <AnalyticCell>
            <AnalyticHeaderValue isSuccess={rawData.profit > 0} isNegative={rawData.profit < 0}>
              {formatMoney(rawData.profit)}
            </AnalyticHeaderValue>
            <AnalyticHeaderName>PROFIT · {rawData.margin}%</AnalyticHeaderName>
            <Grid container>
              {[...types, 'total'].map((type) => {
                const isTotal = type === 'total';
                const margin = (isTotal ? rawData : rawData[type as CatalogType])?.margin;

                return (
                  <Grid item xs={4}>
                    <Box marginTop="10px" display="flex" justifyContent="center" alignItems="center">
                      <Box position="relative" display="inline-flex">
                        <FacebookCircularProgress size={73} value={margin > 0 ? margin : 0} variant="determinate" />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          style={{ flexFlow: 'column' }}
                        >
                          <AnalyticBarName centric>{isTotal ? 'Total' : capitalize(`${type}s`)}</AnalyticBarName>
                          <AnalyticBarValue centric>{(isTotal ? rawData : rawData[type]).margin}%</AnalyticBarValue>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </AnalyticCell>
        </Grid>
      </AnalyticsContainer>
      <Header container justifyContent="space-between">
        <Grid item>
          <SearchBar
            onValueChange={handleSearchValueChange}
            placeholder="Search for product/service…"
            disabled={editMode}
          />
        </Grid>
        <Grid item>
          {!editMode && hasAccess(access!, 'contract', 'edit', 'contract') && (
            <Button
              variant={ButtonVariant.Flat}
              onClick={() => {
                form.reset(itemsToFormValues());
                setEditMode(!editMode);
              }}
              data-testid="btn_editContract"
            >
              <Edit size="16px" />
              Edit Contract
            </Button>
          )}
          {editMode && (
            <Buttons>
              <Button
                onClick={() => {
                  form.reset(itemsToFormValues());
                  setEditMode(!editMode);
                }}
                variant={ButtonVariant.Secondary}
                data-testid="btn_editContract"
              >
                <X size={16} />
                Cancel
              </Button>
              <Button
                variant={ButtonVariant.Primary}
                onClick={form.handleSubmit(handleSubmit)}
                data-testid="btn_editContract"
              >
                <Save size={16} />
                Save Contract
              </Button>
            </Buttons>
          )}
        </Grid>
      </Header>
      <BodyWrapper>
        {types.map((type) => {
          const isProduct = type === CatalogType.PRODUCT;
          const items = editMode ? fieldsByType[type] : result[type].items;
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { price, cost } = (editMode ? watchedFields : result[type].items)?.reduce(
            (acc, field) =>
              field.catalogItem.type === type
                ? {
                    price: acc.price + (editMode ? field.quantity * field.unitPrice : field.totalPrice),
                    cost: acc.cost + (editMode ? field.quantity * field.unitCost : field.totalCost)
                  }
                : acc,
            {
              price: 0,
              cost: 0
            }
          );
          const columnCount = isProduct ? 8 : 7;

          return (
            <>
              <GroupHeader>
                <GroupHeaderTitle
                  onClick={() => {
                    collapsedTypes.toggle(type);
                  }}
                >
                  <IconWrapper>
                    {!collapsedTypes.has(type) ? (
                      <ChevronDown color={colors.gray2} size={20} />
                    ) : (
                      <ChevronRight color={colors.gray2} size={20} />
                    )}
                  </IconWrapper>
                  Bill Of {isProduct ? 'Materials' : 'Services'}
                </GroupHeaderTitle>
              </GroupHeader>
              <Collapse in={!collapsedTypes.has(type)}>
                <GroupBody container direction="row" justifyContent="center" alignItems="center">
                  <FormProvider {...form}>
                    <FinancialTable aria-label="sticky table">
                      <THead>
                        <TRow>
                          <TCell>
                            <ThCell key="name">Name</ThCell>
                          </TCell>
                          <TCell>{isProduct && <ThCell key="quantity">Manufacturer</ThCell>}</TCell>
                          <TCell>
                            <ThCell key="Type">Quantity</ThCell>
                          </TCell>
                          <TCell>
                            <ThCell key="Type">Unit Cost</ThCell>
                          </TCell>
                          <TCell>
                            <ThCell key="Type">Total Cost</ThCell>
                          </TCell>
                          <TCell>
                            <ThCell key="Type">Unit Price</ThCell>
                          </TCell>
                          <TCell>
                            <ThCell key="Type">Total Price</ThCell>
                          </TCell>
                          <TCell />
                        </TRow>
                      </THead>
                      <TBody>
                        {items.map((item) =>
                          editMode ? (
                            <TRow key={item.id}>
                              <TCell>
                                <CatalogItemSelector
                                  onSelect={(selected) =>
                                    fields.some((field) => field.catalogItem.id === selected.id)
                                      ? showError(`${selected.name} is already added`)
                                      : update(item.index, {
                                          description: selected.description,
                                          catalogItem: selected,
                                          unitCost: selected.cost,
                                          unitPrice: selected.price,
                                          quantity: item.quantity,
                                          totalCost: item.quantity * selected.cost,
                                          totalPrice: item.quantity * selected.price
                                        } as LineItem)
                                  }
                                  inputText={item.catalogItem.name}
                                  type={type}
                                />
                                <RichEditorReactForm
                                  name={`items.${item.index}.description`}
                                  placeholder="Enter description..."
                                />
                              </TCell>
                              <TCell>{item.catalogItem.manufacturer}</TCell>
                              <TCell>
                                <NumericField name={`items.${item.index}.quantity`} label="Quantity" />
                              </TCell>
                              <TCell>
                                <NumericField name={`items.${item.index}.unitCost`} label="Unit Cost" />
                              </TCell>
                              <TCell>
                                {
                                  // eslint-disable-next-line no-unsafe-optional-chaining
                                  formatMoney(watchedFields[item.index]?.quantity * watchedFields[item.index]?.unitCost)
                                }
                              </TCell>
                              <TCell>
                                <NumericField name={`items.${item.index}.unitPrice`} label="Unit Price" />
                              </TCell>
                              <TCell>
                                {formatMoney(
                                  // eslint-disable-next-line no-unsafe-optional-chaining
                                  watchedFields[item.index]?.quantity * watchedFields[item.index]?.unitPrice
                                )}
                              </TCell>
                              <TCell>
                                <Remove
                                  size={20}
                                  color={colors.red}
                                  onClick={() => {
                                    remove(item.index);
                                  }}
                                />
                              </TCell>
                            </TRow>
                          ) : (
                            <TRow key={item.id}>
                              <TCell>
                                <ItemWrapper>
                                  {/* <ItemImage /> */}
                                  <ItemName>{item.catalogItem.name}</ItemName>
                                  <ItemStatus />
                                  <ItemDescription>
                                    <p dangerouslySetInnerHTML={{ __html: item.description }} />
                                  </ItemDescription>
                                  <ItemSource>
                                    {logs.find((l) => l.id === item.sourceId) && (
                                      <>
                                        <img src={Aurora} width={20} height={20} alt="Aurora" />
                                        {getIntegrationName(
                                          logs.find((l) => l.id === item.sourceId)?.connectIntegration.type ?? ''
                                        )}
                                        <SourceId>
                                          ID: {logs.find((l) => l.id === item.sourceId)?.sourceProjectId?.slice(0, 5)}
                                        </SourceId>
                                        <a
                                          href={`https://v2.aurorasolar.com/projects/${logs.find((l) => l.id === item.sourceId)?.sourceProjectId}/overview/dashboard`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <ExternalLink size={16} color={colors.blue} />
                                        </a>
                                      </>
                                    )}
                                  </ItemSource>
                                </ItemWrapper>
                              </TCell>
                              <TCell>{isProduct ? item.catalogItem.manufacturer : false}</TCell>
                              <TCell>{item.quantity}</TCell>
                              <TCell>{formatMoney(item.unitCost)}</TCell>
                              <TCell>{formatMoney(item.totalCost)}</TCell>
                              <TCell>{formatMoney(item.unitPrice)}</TCell>
                              <TCell>{formatMoney(item.totalPrice)}</TCell>
                              <TCell />
                            </TRow>
                          )
                        )}
                        {!items.length && (
                          <TRow>
                            <TCell colSpan={columnCount}>
                              <StateMessege title="Nothing added yet" />
                            </TCell>
                          </TRow>
                        )}
                        {editMode && (
                          <TRow>
                            <TCell colSpan={columnCount}>
                              <CatalogItemSelector
                                onSelect={(selected) =>
                                  fields.some((field) => field.catalogItem.id === selected.id)
                                    ? showError(`${selected.name} is already added`)
                                    : append({
                                        description: selected.description,
                                        catalogItem: selected,
                                        unitCost: selected.cost,
                                        unitPrice: selected.price,
                                        quantity: 1,
                                        totalCost: selected.cost,
                                        totalPrice: selected.price
                                      })
                                }
                                type={type}
                              />
                            </TCell>
                          </TRow>
                        )}
                        <TotalTRow>
                          <TCell>
                            <b>Total</b>
                          </TCell>
                          <TCell />
                          <TCell />
                          <TCell />
                          <TCell>{formatMoney(cost)}</TCell>
                          <TCell />
                          <TCell>
                            <b>{formatMoney(price)}</b>
                          </TCell>
                          <TCell />
                        </TotalTRow>
                      </TBody>
                    </FinancialTable>
                  </FormProvider>
                </GroupBody>
              </Collapse>
            </>
          );
        })}
        <GrandTotal>
          <FinancialTable>
            <TBody>
              <TRow>
                <TCell>
                  <b>Grand Total</b>
                </TCell>
                <TCell />
                <TCell />
                <TCell />
                <TCell>{formatMoney(grandTotal.cost)}</TCell>
                <TCell />
                <TCell>
                  <b>{formatMoney(grandTotal.price)}</b>
                </TCell>
                <TCell />
              </TRow>
            </TBody>
          </FinancialTable>
        </GrandTotal>
      </BodyWrapper>
    </Wrapper>
  );
};

const GrandTotal = styled.div`
  padding: 10px;
  border-top: 1px solid #ddd;
`;
const FinancialTable = styled(Table)`
  border-collapse: unset !important;

  th,
  tr {
    background-color: transparent !important;
  }

  tr {
    td {
      :nth-child(1) {
        width: 35%;
      }

      :nth-child(2) {
        width: 10%;
      }

      :nth-child(3) {
        width: 10%;
      }

      :nth-child(4) {
        width: 10%;
      }

      :nth-child(5) {
        width: 10%;
      }

      :nth-child(6) {
        width: 10%;
      }

      :nth-child(7) {
        width: 10%;
      }

      :nth-child(8) {
        width: 5%;
      }

      b {
        font-weight: 500;
      }
    }
  }
`;
const ItemName = styled.div`
  color: #1d1d35;
  font-weight: 500;
  font-size: 14px;
`;
const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
// const ItemImage = styled.div`
//   width: 32px;
//   height: 32px;
//   margin-right: 10px;
//   border: 1px solid #eee;
//   border-radius: 2px;
// `;
const ItemStatus = styled.div``;
const ItemDescription = styled.div`
  width: 100%;

  p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
  }
`;

const ItemSource = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 4px;
`;

const SourceId = styled.span`
  color: #828d9a;
`;

const TotalTRow = styled(TRow)`
  td {
    border-top: 1px solid #e8ecef !important;
    font-weight: bold;
  }
`;
