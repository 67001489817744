import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
`;

export const TableContainer = styled.div``;

export const Table = styled.table`
  background-color: #fff;
  border-spacing: 0;
  border-radius: 8px;
  width: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
  }

  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
    /* background-color: ; */
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #98a9bc;
    height: 48px;
    text-align: left;
    padding: 8px 16px;
    border-bottom: 1px solid #dfdfe8;
    border-top: 1px solid #dfdfe8;

    &:first-child {
      border-left: 1px solid #dfdfe8;
    }

    &:last-child {
      border-right: 1px solid #dfdfe8;
    }
  }
  tr {
    cursor: pointer;

    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  tr.readonly {
    cursor: default;
    &:hover {
      background-color: transparent;
    }
  }

  td {
    overflow: hidden;
    border-bottom: 1px solid #dfdfe8;
    padding: 8px 16px;
    height: 64px;
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;

    &:last-child {
      border-right: 1px solid #dfdfe8;
      > * {
        margin-left: auto;
        justify-content: flex-end;
        text-align: right;
      }
    }

    &:first-child {
      border-left: 1px solid #dfdfe8;
    }
  }

  td.no-break {
    white-space: nowrap;
  }
`;
