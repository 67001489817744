import styled from 'styled-components';
import { CSSProperties } from 'react';
import { SystemColumn } from '@hooks/systems';

export const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

// when update this - also update styes of CoveringMapInner
export const columnStyleWithMap: { [column in SystemColumn]?: CSSProperties } = {
  [SystemColumn.name]: {
    width: 250,
    maxWidth: 250,
    minWidth: 250
  },
  [SystemColumn.status]: {
    width: 96,
    maxWidth: 96,
    minWidth: 96
  },
  [SystemColumn.address]: {
    width: 160,
    maxWidth: 160,
    minWidth: 160
  },
  [SystemColumn.peakPower]: {
    width: 105,
    maxWidth: 105,
    minWidth: 105
  },
  [SystemColumn.sevenDayAverage]: {
    width: 136,
    maxWidth: 136,
    minWidth: 136
  }
};

export const columnStyleWithoutMap: { [column in SystemColumn]?: CSSProperties } = {
  [SystemColumn.sevenDayAverage]: {
    width: 136,
    maxWidth: 136,
    minWidth: 136
  }
};
