import styled from 'styled-components';
import { colors, scroll } from '@styles';

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? colors.green : '#828D9A')};
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;

  border-color: ${({ isActive }) => (isActive ? colors.green : 'transparent')};
`;

export const List = styled.div`
  overflow: auto;
  max-height: calc(100% - 72px);
  ${scroll};
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
`;

export const CellTop = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const CellBotton = styled.div`
  color: #828d9a;
`;

export const Row = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr auto;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 4px;
  border-bottom: 1px solid #dfdfe8;
  transition: background-color 0.15s ease-in-out;
  font-size: 14px;
  font-weight: 400;

  svg {
    flex-shrink: 0;
  }

  &:last-child {
    border-bottom: 0;
    margin-left: auto;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
`;
