import moment from 'moment';
import { ProjectDetail } from '@types';
import { defaultTheme } from '@themes';
import { ProjectStage } from '@generated/types/graphql';
import { isString } from 'lodash';

type GetSlaParam = Pick<ProjectDetail, 'projectStageUpdates'>;

const makeGetSlaDiff = (fieldName: keyof ProjectStage) => (record: GetSlaParam) => {
  const lastUpdate = record?.projectStageUpdates?.[0];

  if (!lastUpdate || !lastUpdate.stage || !lastUpdate.stage[fieldName]) {
    return false;
  }

  const value = lastUpdate.createdAt;
  const daysInStage = moment().diff(value, 'days');

  const diff = lastUpdate.stage[fieldName] - daysInStage;

  return diff <= 0 ? daysInStage : undefined;
};

export const getRedSlaOverdueInDays = makeGetSlaDiff('redSla');
export const getYellowSlaOverdueInDays = makeGetSlaDiff('yellowSla');
export const getTimeInStageInDays = (record: GetSlaParam) => {
  const lastUpdate = record?.projectStageUpdates?.[0];

  if (!lastUpdate || !lastUpdate.createdAt) {
    return undefined;
  }

  return moment().diff(lastUpdate.createdAt, 'days');
};

export const getSlaColor = (record: GetSlaParam) => {
  const redSla = getRedSlaOverdueInDays(record);
  const yellowSla = getYellowSlaOverdueInDays(record);

  if (redSla) {
    return defaultTheme.colors.red;
  }

  if (yellowSla) {
    return defaultTheme.colors.yellow;
  }

  return undefined;
};

export const parseLocation = (location: string | string[]): { lat: number; lng: number } | null => {
  if (Array.isArray(location) && location.length === 1 && isString(location[0])) {
    const [lat, lng] = location[0].split(',').map((pos) => parseFloat(pos)) as [number, number];

    return { lat, lng };
  }

  if (isString(location)) {
    const [lat, lng] = location.split(',').map((pos) => parseFloat(pos)) as [number, number];

    return { lat, lng };
  }

  return null;
};
