import { colors } from '@styles';
import { DateTime } from 'luxon';
import React from 'react';
import { ResponsiveContainer, LineChart, Line, Tooltip, XAxis, YAxis, ComposedChart, ReferenceLine } from 'recharts';
import { ChartPeriod } from '@hooks/systems';
import { ChartToolTip } from '../ChartTooltip';

type ChartPoint = {
  time: DateTime;
  value: number;
};

type Props = {
  points: ChartPoint[];
  monitored: boolean;
};

export const SystemEnergyAverageChart: React.FC<Props> = ({ points, monitored }) => {
  // Empty State
  if (points.length === 0) {
    return (
      <ResponsiveContainer width={104} height={50}>
        {monitored && (
          <ComposedChart data={[{ value: 0 }, { value: 0 }]}>
            <ReferenceLine y={0} stroke="#DFDFE8" strokeDasharray="4 4" />
            <XAxis hide />
            <YAxis hide domain={[-1, 1]} />
          </ComposedChart>
        )}
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer width={104} height={28}>
      <LineChart data={points} margin={{ top: 10, right: 10, bottom: 0, left: 0 }}>
        <Tooltip
          cursor={{ stroke: colors.gray5 }}
          filterNull={false}
          wrapperStyle={{ zIndex: 1000 }}
          position={{ x: 0, y: -50 }}
          content={<ChartToolTip period={ChartPeriod.week} kind="energy" />}
        />

        <XAxis type="category" dataKey={({ time }: ChartPoint) => time.toSeconds()} tickLine={false} hide />

        <YAxis hide />

        <Line
          type="linear"
          dataKey="value"
          stroke={colors.green}
          strokeWidth={1}
          dot={false}
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
