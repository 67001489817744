import React from 'react';
import { FilterWithGroups } from '@components/Scheduler/components/Filters/FilterWithGroups';
import { SystemStatus } from '@generated/types/graphql';
import { STATUS_OPTIONS } from '@features/SystemPortfolio/constants';
import { useClientFilterState } from '../../useClientFilterState';

export const Status = () => {
  const { clientFilters, updateFilters } = useClientFilterState();

  const handleFilterChange = (selected: number[]) => {
    const selectedStatuses = selected
      .map((id) => {
        const statusOption = STATUS_OPTIONS.find((option) => option.id === id);

        return statusOption?.value;
      })
      .filter(Boolean) as SystemStatus[];

    updateFilters({ status: selectedStatuses });
  };

  const selectedFilters = STATUS_OPTIONS.filter((option) => clientFilters.status.includes(option.value)).map(
    (option) => option.id
  );

  return (
    <FilterWithGroups
      label="Status"
      fieldName="statusFilters"
      onSelect={({ statusFilters }) => handleFilterChange(statusFilters)}
      groups={[{ title: 'Status', options: STATUS_OPTIONS }]}
      selected={selectedFilters}
      showAllIfNoneSelected
    />
  );
};
