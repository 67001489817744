import { AccountStatus, ProjectStageType } from '@types';
import { ActionStatus, SystemStatus, TaskStatus } from '@generated/types/graphql';

export const colors = {
  green: '#009688',
  blue: '#4d7cfe',
  black: '#1d1d35',
  black2: '#1B1E24',
  white: '#ffffff',
  white2: '#f8fafb',
  white3: '#f2f4f6',
  red: '#d54855',
  orange: '#ff5c00',
  orange2: 'orange',
  yellow: '#f0bc4d',
  gray: '#98a9bc',
  gray2: '#778ca2',
  gray3: '#e8ecef',
  gray4: '#f1f4f4',
  gray5: '#828D9A',
  borderDark: '#d2d9e1',

  greenBg: '#cceae7',
  blueBg: '#dbe5ff',
  yellowBg: '#fcf2db',
  gray2Bg: '#e4e8ec',
  redBg: '#f7dadd'
};

export default colors;

export const CoperniqColorsScheme = [
  {
    id: -1,
    name: 'Gray',
    color: colors.gray
  },
  {
    id: 1,
    name: 'Red',
    color: '#ED4947'
  },
  {
    id: 2,
    name: 'Pink',
    color: '#E9386F'
  },
  {
    id: 3,
    name: 'Purple',
    color: '#A13EB4'
  },
  {
    id: 4,
    name: 'Deep Purple',
    color: '#734DBA'
  },
  {
    id: 5,
    name: 'Blue',
    color: '#399BF3'
  },
  {
    id: 6,
    name: 'Cyan',
    color: '#22BFD5'
  },
  {
    id: 7,
    name: 'Green',
    color: '#5BB25F'
  },
  {
    id: 8,
    name: 'Yellow',
    color: '#FFC324'
  },
  {
    id: 9,
    name: 'Orange',
    color: '#FF9D23'
  },
  {
    id: 10,
    name: 'Dark',
    color: '#1A2D4F'
  },
  {
    id: 11,
    name: 'Brown',
    color: '#795548'
  },
  {
    id: 12,
    name: 'Deep orange',
    color: '#FF5722'
  },
  {
    id: 13,
    name: 'Blue gray',
    color: '#607D8B'
  },
  {
    id: 14,
    name: 'Indigo',
    color: '#3F51B5'
  },
  {
    id: 15,
    name: 'Light green',
    color: '#8BC34A'
  },
  {
    id: 16,
    name: 'Lime',
    color: '#CDDC39'
  }
] as { id: number; name: string; color: string }[] & { colorValues: string[] };

CoperniqColorsScheme.colorValues = CoperniqColorsScheme.map(({ color }) => color);

export const ACCOUNT_STATUS_COLORS: { [status in AccountStatus]: string } = {
  [AccountStatus.LEAD]: colors.yellow,
  [AccountStatus.PROSPECT]: colors.blue,
  [AccountStatus.CUSTOMER]: colors.green
};

export enum ProjectTypeColor {
  initiation = '#F0BC4D',
  new = '#F0BC4D',
  selling = '#4D7CFE',
  won = '#009688',
  production = '#4D7CFE',
  complete = '#009688',
  lost = '#D54855',
  cancelled = '#D54855'
}

export const PROJECT_STAGE_COLORS: { [Type in ProjectStageType]: ProjectTypeColor } = {
  [ProjectStageType.initiation]: ProjectTypeColor.initiation,
  [ProjectStageType.new]: ProjectTypeColor.new,
  [ProjectStageType.selling]: ProjectTypeColor.selling,
  [ProjectStageType.won]: ProjectTypeColor.won,
  [ProjectStageType.production]: ProjectTypeColor.production,
  [ProjectStageType.complete]: ProjectTypeColor.complete,
  [ProjectStageType.lost]: ProjectTypeColor.lost,
  [ProjectStageType.cancelled]: ProjectTypeColor.cancelled
};

export const RECORD_STAGE_TYPE_COLORS: { [Type in ProjectStageType]: { color: string; background: string } } = {
  [ProjectStageType.initiation]: { color: '#F1AA12', background: '#FCF2DB' },
  [ProjectStageType.new]: { color: '#F1AA12', background: '#FCF2DB' },
  [ProjectStageType.selling]: { color: '#4D7CFE', background: '#DBE5FF' },
  [ProjectStageType.production]: { color: '#4D7CFE', background: '#DBE5FF' },
  [ProjectStageType.won]: { color: '#009A47', background: '#CDF3DF' },
  [ProjectStageType.complete]: { color: '#009A47', background: '#CDF3DF' },
  [ProjectStageType.lost]: { color: '#D54855', background: '#F7DADD' },
  [ProjectStageType.cancelled]: { color: '#D54855', background: '#F7DADD' }
};

export const TASK_STATUS_COLORS: { [Type in TaskStatus]: { color: string; background?: string } } = {
  [TaskStatus.Assigned]: { color: '#F0BC4D' },
  [TaskStatus.ChangesRequested]: { color: '#D54855' },
  [TaskStatus.Cancelled]: { color: '#D54855', background: '#F7DADD' },
  [TaskStatus.Completed]: { color: '#009A47', background: '#CDF3DF' },
  [TaskStatus.Dispatched]: { color: '#F0BC4D', background: '#FCF2DB' },
  [TaskStatus.Queued]: { color: '#778CA2' },
  [TaskStatus.Review]: { color: '#009688' },
  [TaskStatus.Scheduled]: { color: '#778CA2', background: '#E4E8EC' },
  [TaskStatus.Working]: { color: '#4D7CFE', background: '#DBE5FF' },
  [TaskStatus.ClockedIn]: { color: '#4D7CFE', background: '#DBE5FF' },
  [TaskStatus.Waiting]: { color: '#683EB6' },
  [TaskStatus.OnTheWay]: { color: '#4D7CFE' },
  [TaskStatus.ClockedOut]: { color: '#683EB6' }
};

export const ACTION_ITEM_STATUS_COLORS: { [Type in ActionStatus]: { color: string; background?: string } } = {
  [ActionStatus.Requested]: { color: '#F1AA12', background: '#FCF2DB' },
  [ActionStatus.InReview]: { color: '#009A47' },
  [ActionStatus.Completed]: { color: '#009A47', background: '#CDF3DF' },
  [ActionStatus.Cancelled]: { color: '#D54855', background: '#F7DADD' }
};

export const SYSTEM_STATUS_COLORS: { [status in SystemStatus]: { color: string; bgColor?: string } } = {
  [SystemStatus.Normal]: {
    color: '#009A47',
    bgColor: '#CDF3DF'
  },
  [SystemStatus.Warning]: {
    color: '#F0BC4D',
    bgColor: '#FCF2DB'
  },
  [SystemStatus.Error]: {
    color: '#D54855',
    bgColor: '#F7DADD'
  }
};

export const WHISKERS_COLORS: { [Type in TaskStatus]?: { fill: string; stroke: string; line?: string } } = {
  [TaskStatus.ClockedOut]: { fill: '#683EB6', stroke: '#683EB6' },
  [TaskStatus.OnTheWay]: { fill: '#FFFFFF', stroke: '#4D7CFE', line: '#778CA2' },
  [TaskStatus.ClockedIn]: { fill: '#4D7CFE', stroke: '#4D7CFE', line: '#4D7CFE' }
};
