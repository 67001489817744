import {
  ClientFilterState,
  GroupField,
  GroupOption,
  SortField,
  SortOption,
  SourceOption,
  SystemColumn,
  SystemWithStats
} from '@hooks/systems';
import { IntegrationProvider, System, SystemStatus } from '@generated/types/graphql';
import { DateTime } from 'luxon';
import { formatEnergy, formatPower } from '@features/SystemPortfolio/utils';
import { PROVIDER_CONFIGS_MAP } from '@features/Platform/SystemsSettings/constants';

export const PER_PAGE_OPTIONS = [10, 20, 50].map((value) => ({ label: value.toString(), value }));

export const COLUMNS: { id: SystemColumn; name: string; stats?: boolean }[] = [
  { id: SystemColumn.name, name: 'Source Name' },
  { id: SystemColumn.sevenDayAverage, name: '7-Day Average' },
  { id: SystemColumn.status, name: 'Status' },
  { id: SystemColumn.address, name: 'Site' },
  { id: SystemColumn.profile, name: 'Fleet' },
  { id: SystemColumn.peakPower, name: 'Peak Power', stats: true },
  { id: SystemColumn.size, name: 'Size (W)' },
  { id: SystemColumn.provider, name: 'Source' },
  { id: SystemColumn.clientType, name: 'Client Type' },
  { id: SystemColumn.installedAt, name: 'Installation Date' },
  { id: SystemColumn.operationalAt, name: 'PTO Date' },
  { id: SystemColumn.operationStatus, name: 'Operation Status' },
  { id: SystemColumn.providerId, name: 'Source ID' },
  { id: SystemColumn.connectionType, name: 'Connection Type' },
  { id: SystemColumn.lastReport, name: 'Last Report' },
  { id: SystemColumn.notes, name: 'Notes' },
  { id: SystemColumn.productionYesterday, name: 'Energy Produced - Yesterday', stats: true },
  { id: SystemColumn.productionWeek, name: 'Energy Produced - This Week', stats: true },
  { id: SystemColumn.productionMonth, name: 'Energy Produced - This Month', stats: true },
  { id: SystemColumn.productionYear, name: 'Energy Produced - This Year', stats: true },
  { id: SystemColumn.productionLifetime, name: 'Energy Produced - Lifetime', stats: true },
  { id: SystemColumn.consumptionYesterday, name: 'Energy Consumed - Yesterday', stats: true },
  { id: SystemColumn.consumptionWeek, name: 'Energy Consumed - This Week', stats: true },
  { id: SystemColumn.consumptionMonth, name: 'Energy Consumed - This Month', stats: true },
  { id: SystemColumn.consumptionYear, name: 'Energy Consumed - This Year', stats: true },
  { id: SystemColumn.consumptionLifetime, name: 'Energy Consumed - Lifetime', stats: true }
];

export const COLUMNS_BY_ID = COLUMNS.reduce<{ [id in SystemColumn]?: (typeof COLUMNS)[0] }>((acc, column) => {
  acc[column.id] = column;

  return acc;
}, {});

export const renderColumnPlain = (system: System | SystemWithStats, column: SystemColumn): string | undefined => {
  switch (column) {
    case SystemColumn.name:
      return system.name;
    case SystemColumn.status:
      return system.status;
    case SystemColumn.provider:
      return providerNames[system.integration.provider as IntegrationProvider];
    case SystemColumn.installedAt:
      return system.installedAt ? DateTime.fromISO(system.installedAt).toLocaleString(DateTime.DATETIME_SHORT) : '';
    case SystemColumn.operationalAt:
      return system.operationalAt ? DateTime.fromISO(system.operationalAt).toLocaleString(DateTime.DATETIME_SHORT) : '';
    case SystemColumn.operationStatus:
      return system.operationStatus;
    case SystemColumn.address:
      return system.addressForced ?? system.address;
    case SystemColumn.addressCity:
      return system.addressCity;
    case SystemColumn.addressState:
      return system.addressState;
    case SystemColumn.addressStreet:
      return system.addressStreet;
    case SystemColumn.addressZip:
      return system.addressZip;
    case SystemColumn.providerId:
      return system.providerId;
    case SystemColumn.connectionType:
      return system.connectionType;
    case SystemColumn.lastReport:
      return system.lastReportAt ? DateTime.fromISO(system.lastReportAt).toLocaleString(DateTime.DATETIME_SHORT) : '';
    case SystemColumn.size:
      return '' + (system.size || '');
    case SystemColumn.number:
      return system.number;
    case SystemColumn.productionYesterday:
      return formatEnergy((system as SystemWithStats).productionYesterday);
    case SystemColumn.productionWeek:
      return formatEnergy((system as SystemWithStats).productionWeek);
    case SystemColumn.productionMonth:
      return formatEnergy((system as SystemWithStats).productionMonth);
    case SystemColumn.productionYear:
      return formatEnergy((system as SystemWithStats).productionYear);
    case SystemColumn.productionLifetime:
      return formatEnergy((system as SystemWithStats).productionLifetime);
    case SystemColumn.consumptionYesterday:
      return formatEnergy((system as SystemWithStats).consumptionYesterday);
    case SystemColumn.consumptionWeek:
      return formatEnergy((system as SystemWithStats).consumptionWeek);
    case SystemColumn.consumptionMonth:
      return formatEnergy((system as SystemWithStats).consumptionMonth);
    case SystemColumn.consumptionYear:
      return formatEnergy((system as SystemWithStats).consumptionYear);
    case SystemColumn.consumptionLifetime:
      return formatEnergy((system as SystemWithStats).consumptionLifetime);
    case SystemColumn.peakPower:
      return formatPower((system as SystemWithStats).peakPower);
    case SystemColumn.clientType:
      return system.clientType;
    case SystemColumn.notes:
      return system.notes;
    case SystemColumn.profile:
      return system.profile?.name;
    default:
      throw new Error(`Unrecognized column ${column}`);
  }
};

export const GROUP_OPTIONS: GroupOption[] = [{ id: GroupField.status, name: 'Status' }];

export const SORT_OPTIONS: SortOption[] = [
  { id: SortField.number, name: 'Number' },
  { id: SortField.name, name: 'Name' },
  { id: SortField.operationalAt, name: 'Operational At' }
];

export const SOURCE_OPTIONS: SourceOption[] = Object.values(PROVIDER_CONFIGS_MAP).map((providerConfig, index) => ({
  id: String(index),
  name: providerConfig.name,
  provider: providerConfig.id,
  isMock: providerConfig.isMock
}));

export const STATUS_OPTIONS = [
  { id: 1, title: 'Normal', value: SystemStatus.Normal },
  { id: 2, title: 'Warning', value: SystemStatus.Warning },
  { id: 3, title: 'Error', value: SystemStatus.Error }
];

// see https://docs.google.com/spreadsheets/d/1_BEhGsriuRo1b0qm-P4fbeFGPwAEs_BOE5199u4OQEQ/edit#gid=1894194458
export const ENPHASE_STATUSES: { [rawStatus: string]: { desc: string } } = {
  normal: {
    desc: 'System Normal'
  },
  monitored: {
    desc: 'Monitored'
  },
  on_grid: {
    desc: 'On Grid'
  },
  deactivated: {
    desc: 'System Deactivated'
  },
  storage_idle: {
    desc: 'Storage Inactive'
  },
  retired: {
    desc: 'Retired'
  },
  not_monitored: {
    desc: 'Not Monitored'
  },
  unused: {
    desc: 'Unused'
  },
  disabled: {
    desc: 'Not Enabled'
  },
  debug: {
    desc: 'Informational'
  },
  info: {
    desc: 'Informational'
  },
  muted: {
    desc: 'Alerts Disabled'
  },
  unknown: {
    desc: 'Updating Data'
  },
  data_upload: {
    desc: 'Data upload in progress'
  },
  off_grid: {
    desc: 'Off Grid'
  },
  grid_unknown: {
    desc: 'Grid Status Unknown'
  },
  micro: {
    desc: 'Microinverters Not Reporting'
  },
  meter: {
    desc: 'Meter Not Reporting'
  },
  battery: {
    desc: 'AC Batteries Not Reporting'
  },
  encharge: {
    desc: 'Encharge Not Reporting'
  },
  enpower: {
    desc: 'Enpower Not Reporting'
  },
  warning: {
    desc: 'Warning'
  },
  no_data: {
    desc: 'No Data'
  },
  nsr: {
    desc: 'Q Relay Not Reporting'
  },
  any: {
    desc: 'Any Problem'
  },
  comm: {
    desc: 'Envoy Not Reporting'
  },
  power: {
    desc: 'System Production Issue'
  },
  Error: {
    desc: 'Error'
  },
  rma: {
    desc: 'RMA'
  },
  meter_issue: {
    desc: 'Meter Issue'
  },
  encharge_issue: {
    desc: 'Encharge Issue'
  },
  enpower_issue: {
    desc: 'Enpower Issue'
  }
};

export const getStatusDesc = (system: System): string => {
  switch (system.integration.provider) {
    case IntegrationProvider.Enfake:
    case IntegrationProvider.Enphase:
      return ENPHASE_STATUSES[system.providerStatus]?.desc;

    case IntegrationProvider.LunarEdge:
    case IntegrationProvider.SolarEdge:
      // TODO: pull alert descriptions from SolarEdge somehow
      return system.providerStatus;
    default:
      throw new Error(system.integration.provider satisfies never);
  }
};

export const providerNames: { [provider in IntegrationProvider]: string } = {
  [IntegrationProvider.Enphase]: 'Enphase API',
  [IntegrationProvider.Enfake]: 'Mock Enphase API',
  [IntegrationProvider.SolarEdge]: 'SolarEdge API',
  [IntegrationProvider.LunarEdge]: 'Mock SolarEdge API'
};

export const DEFAULT_CLIENT_FILTER_STATE: ClientFilterState = {
  sortBy: [{ option: SORT_OPTIONS[0], isDesc: false }],
  query: '',
  groupBy: null,
  perPage: PER_PAGE_OPTIONS[0],
  monitored: [],
  linking: [],
  status: [],
  source: [],
  fleet: [],
  alerts: []
};
