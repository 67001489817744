import React, { useEffect, useCallback, useState } from 'react';

import { Link } from 'gatsby';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { SelectField, useForm, Form, FormValidationRules } from '@kit/components/Form';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useNavigate } from '@reach/router';
import { useUpdateAuroraIntegration, useConnectIntegration } from '@hooks/workspace/connectIntegration';
import { useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { PropertyForm } from '@features/Platform/Properties/List/PropertyForm';
import { Plus } from 'react-feather';
import { RecordType } from '@types';
import { SearchBar } from '@common/SearchBar';
import { useModal } from '@common/PromiseModal';
import { debounce } from 'lodash/fp';
import {
  Breadcrumbs,
  Container,
  Description,
  Header,
  Title,
  SectionContainer,
  SubTitle,
  SubTitle2,
  MatchDescription,
  ButtonsContainer
} from './styled';

import { History } from './History';
import { MapPropertiesTable, CustomPropertiesTable } from './MapPropertiesTable';
import { propertiesToMap } from './MapPropertiesTable/MapPropertiesConfig';

interface Props {
  connectIntegrationId: string;
}

type FormValues = {
  matchClientColumnId: { label: string; value: number };
  matchProjectColumnId: { label: string; value: number };
  matchFoundStrategy: { label: string; value: string };
  workflow: { label: string; value: number };
};

const RULES: FormValidationRules<FormValues> = {
  matchClientColumnId: {
    isRequired: true
  },
  matchProjectColumnId: {
    isRequired: true
  },
  matchFoundStrategy: {
    isRequired: true
  }
};

const matchOptions = [
  { label: 'Title', value: -1 },
  { label: 'Primary email', value: -32 },
  { label: 'Primary phone', value: -33 },
  { label: 'Site Address', value: -3 },
  { label: 'Project size', value: -44 },
  { label: 'Project value', value: -43 }
];

const matchStrategyOptions = [
  { label: 'Replace', value: 'REPLACE' },
  { label: 'Skip', value: 'SKIP' },
  { label: 'Enrich', value: 'ENRICH' }
];

export const AuroraIntegration = ({ connectIntegrationId }: Props) => {
  const { data: connectIntegration } = useConnectIntegration({ id: Number(connectIntegrationId) });
  const { openModal } = useModal();
  const { mutateAsync: update } = useUpdateAuroraIntegration();
  const { data: allWorkflows = [] } = useWorkflowList();
  const navigate = useNavigate();
  const [defaultSearch, setDefaultSearch] = useState('');
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const [additionalSearch, setAdditionalSearch] = useState('');

  const { handleSubmit, form } = useForm<FormValues>({
    defaultValues: propertiesToMap.reduce((acc: any, item) => {
      acc[item.name] = item.defaultValue;

      return acc;
    }, {}),
    onSubmit: async ({ matchClientColumnId, matchProjectColumnId, matchFoundStrategy, workflow, ...rest }) => {
      const propertiesMap = Object.keys(rest).reduce((acc, item) => {
        const value = rest[item];
        if (value && Array.isArray(value) && value.filter((item) => !!item).length) {
          acc[item] = value;
        }
        if (value && !Array.isArray(value)) {
          acc[item] = value;
        }

        return acc;
      }, {});
      update({
        integrationId: Number(connectIntegrationId),
        matchClientColumnId: matchClientColumnId.value,
        matchProjectColumnId: matchProjectColumnId.value,
        matchFoundStrategy: matchFoundStrategy.value,
        blueprintId: workflow?.value,
        propertiesMap
      });
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchDefaultValueChange = useCallback(debounce(300, setDefaultSearch), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchAdditionalValueChange = useCallback(debounce(300, setAdditionalSearch), []);

  const handleAddNewProperty = useCallback(() => {
    openModal<void>(({ onClose }) => <PropertyForm groups={[]} scope={RecordType.PROJECT} onClose={onClose} />, {
      title: 'Create new property'
    });
  }, [openModal]);

  const {
    control,
    setValue,
    formState: { isSubmitting }
  } = form;

  useEffect(() => {
    if (connectIntegration) {
      setValue(
        'matchClientColumnId',
        matchOptions.find((m) => m.value === connectIntegration.matchClientColumnId)
      );
      setValue(
        'matchProjectColumnId',
        matchOptions.find((m) => m.value === connectIntegration.matchProjectColumnId)
      );
      setValue(
        'matchFoundStrategy',
        matchStrategyOptions.find((m) => m.value === connectIntegration.matchFoundStrategy)
      );
      if (connectIntegration.blueprintId) {
        setValue(
          'workflow',
          workflowOptions.find((m) => m.value === connectIntegration.blueprintId)
        );
      }
    }
  }, [connectIntegration, setValue, workflowOptions]);

  useEffect(() => {
    if (allWorkflows?.length > 0) {
      setWorkflowOptions(
        allWorkflows
          .filter((w) => w.type === RecordType.PROJECT)
          .map((w) => ({
            label: w.name,
            value: w.id
          }))
      );
    }
  }, [allWorkflows]);

  return (
    <Container>
      <Breadcrumbs>
        <Link to="../">Integrations</Link>
        <ChevronRightIcon size="16px" color="#828D9A" />
        <div>Aurora Solar</div>
      </Breadcrumbs>
      <Header>
        <Title>Aurora Solar</Title>
        <Description>
          Get seamless access to solar design data from Aurora, enabling automated data transfers and enhanced project
          management within your platform.
        </Description>
      </Header>
      <History connectIntegrationId={+connectIntegrationId} />
      <Form rules={RULES} onSubmit={handleSubmit}>
        <SectionContainer>
          <SubTitle>
            Default Properties
            <SearchBar placeholder="Search property" onValueChange={handleSearchDefaultValueChange} />
          </SubTitle>
          <MapPropertiesTable control={control} search={defaultSearch.toLocaleLowerCase()} />
        </SectionContainer>

        <SectionContainer>
          <SubTitle>
            Additional Properties
            <SearchBar placeholder="Search property" onValueChange={handleSearchAdditionalValueChange} />
          </SubTitle>
          <CustomPropertiesTable control={control} search={additionalSearch.toLocaleLowerCase()} />
          <Button
            variant={ButtonVariant.Flat}
            size={ButtonSize.Large}
            style={{ marginTop: '16px' }}
            onClick={handleAddNewProperty}
          >
            <Plus size="16px" />
            Project Property
          </Button>
        </SectionContainer>

        <SectionContainer>
          <SubTitle>Workflow</SubTitle>
          <SelectField
            control={control}
            name="workflow"
            placeholder="Select workflow"
            options={workflowOptions}
            isClearable
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </SectionContainer>

        <SectionContainer>
          <SubTitle>Match existing Clients</SubTitle>
          <SubTitle2>Matching/deduplication section</SubTitle2>
          <MatchDescription>
            To avoid duplicates, we can check the Clients in Coperniq and compare them. If there’s a match then missing
            fields can be added or everything can be updated.
          </MatchDescription>

          <SelectField
            control={control}
            name="matchClientColumnId"
            label="Coperniq Client Property to compare"
            options={matchOptions}
            isClearable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          <div style={{ margin: '40px 0', border: 'solid 1px #DFDFE8' }} />
          <SubTitle>Match existing Projects</SubTitle>
          <SubTitle2>Matching/deduplication section</SubTitle2>
          <MatchDescription>
            To avoid duplicates, we can check the Projects in Coperniq and compare them. If there’s a match then missing
            fields can be added or everything can be updated.
          </MatchDescription>
          <SelectField
            control={control}
            name="matchProjectColumnId"
            label="Coperniq Project Property to compare"
            options={matchOptions}
            isClearable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
          <SelectField
            control={control}
            name="matchFoundStrategy"
            label="if a match is found"
            options={matchStrategyOptions}
            isClearable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </SectionContainer>

        <ButtonsContainer>
          <Button disabled={isSubmitting} variant={ButtonVariant.Secondary} onClick={() => navigate(`..`)}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
            Save
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};
