import { selectCompanySettings } from '@state/selectors';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useAppSelector } from '@hooks/store';
import { Settings } from '@state/reducers/authentication/types';

const defaultOption = {
  requestOptions: {
    componentRestrictions: {
      country: ['us']
    }
  }
};

export const getAutocompleteOptions = (features: Settings['features']) => {
  const isGeoDebug = features?.geolocationDebug;

  if (isGeoDebug) {
    return {};
  }

  const supportedAddressCountries = features?.supportedAddressCountries;

  if (!Array.isArray(supportedAddressCountries) || supportedAddressCountries.length === 0) {
    return defaultOption;
  }

  return {
    requestOptions: {
      componentRestrictions: {
        country: supportedAddressCountries
      }
    }
  };
};

export const usePlaces = () => {
  const settings = useAppSelector(selectCompanySettings);

  return usePlacesAutocomplete(getAutocompleteOptions(settings?.features));
};
