import { ConnectHistory } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';

export const useConnectIntegrationHistoryByProject = (args: { projectId: number }) =>
  useQuery<ConnectHistory[]>(
    [ReactQueryKey.ConnectAuroraHistory, 'useConnectIntegrationHistoryByProject', args],
    async () => {
      try {
        return (
          await postGraphql<{ connectHistories: ConnectHistory[] }>(
            gql`
              query CONNECT_HISTORIES_LIST_QUERY($projectId: Int) {
                connectHistories(filter: { coperniqProjectId: { equalTo: $projectId } }) {
                  agreementName
                  agreementId
                  connectIntegrationId
                  coperniqProjectId
                  createdAt
                  id
                  projectName
                  sourceProjectId
                  sourceStatus
                  status
                  connectIntegration {
                    type
                  }
                  coperniqProject {
                    title
                  }
                }
              }
            `,
            args
          )
        ).connectHistories;
      } catch (e) {
        throw apiErrorHandler('Error fetching connect history by project', e);
      }
    },
    {
      initialData: []
    }
  );
