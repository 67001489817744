export const getIntegrationName = (integration: string) => {
  if (!integration) {
    return '';
  }

  return integration
    .toLowerCase()
    .split('_')
    .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
    .join(' ');
};
