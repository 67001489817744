import { IntegrationProvider, System, SystemStatus } from '@generated/types/graphql';
import { DateTime } from 'luxon';
import { GroupField, SortField } from './constants';

export type SystemStats = {
  productionToday: number;
  productionYesterday: number;
  productionWeek: number;
  productionMonth: number;
  productionYear: number;
  productionLifetime: number;
  consumptionToday: number;
  consumptionYesterday: number;
  consumptionWeek: number;
  consumptionMonth: number;
  consumptionYear: number;
  consumptionLifetime: number;
  peakPower: number;
};

export type SystemWithStats = System & SystemStats;

export type EnergyPoint = {
  time: DateTime;
  production?: number;
  consumption?: number;
};

export type PowerPoint = {
  time: DateTime;
  power?: number;
};

export type ChartPoint = EnergyPoint | PowerPoint;

export type MonitoredType = 'Monitored' | 'Not Monitored';
export type LinkingType = 'Linked' | 'Not Linked';

export type GroupOption = {
  id: GroupField;
  name: string;
};

export type SortOption = {
  id: SortField;
  name: string;
};

export type Sort = {
  option: SortOption;
  isDesc: boolean;
};

export type SourceOption = {
  id: string;
  name: string;
  provider: IntegrationProvider;
  isMock: boolean;
};

export type ClientFilterState = {
  sortBy: Sort[];
  query: string;
  groupBy: GroupOption | null;
  perPage: { label: string; value: number };
  monitored: MonitoredType[];
  linking: LinkingType[];
  source: SourceOption[];
  status: SystemStatus[];
  fleet: number[];
  alerts: number[];
};

export enum SystemColumn {
  name = 'name',
  status = 'status',
  sevenDayAverage = 'sevenDayAverage',
  provider = 'provider',
  installedAt = 'installedAt',
  operationalAt = 'operationalAt',
  operationStatus = 'operationStatus',
  address = 'address',
  addressCity = 'addressCity',
  addressStreet = 'addressStreet',
  addressState = 'addressState',
  addressZip = 'addressZip',
  providerId = 'providerId',
  connectionType = 'connectionType',
  lastReport = 'lastReport',
  size = 'size',
  productionYesterday = 'productionYesterday',
  productionWeek = 'productionWeek',
  productionMonth = 'productionMonth',
  productionYear = 'productionYear',
  productionLifetime = 'productionLifetime',
  consumptionYesterday = 'consumptionYesterday',
  consumptionWeek = 'consumptionWeek',
  consumptionMonth = 'consumptionMonth',
  consumptionYear = 'consumptionYear',
  consumptionLifetime = 'consumptionLifetime',
  number = 'number',
  peakPower = 'peakPower',
  clientType = 'clientType',
  notes = 'notes',
  profile = 'profile'
}

export type ProductionChartById = {
  [id: string]: {
    total: number;
    points: { time: DateTime; value: number }[];
  };
};
