import React from 'react';
import { isNumber } from 'lodash';
import { DateTime, Interval } from 'luxon';
import { Circle } from 'react-feather';
import {
  PointTooltip,
  TooltipLabel,
  TooltipValue
} from '@features/SystemPortfolio/List/widgets/ProductionWidget/styled';
import { formatEnergy, formatPower } from '@features/SystemPortfolio/utils';
import { ChartPeriod, chartPeriodConfig } from '@hooks/systems';
import { colors } from '@styles';

enum Items {
  production = 'production',
  consumption = 'consumption',
  power = 'power'
}

type Kind = 'energy' | 'power';

const formatTooltipTime = (time: DateTime, kind: Kind, period: ChartPeriod): string => {
  const periodConfig = chartPeriodConfig[period];
  switch (period) {
    case ChartPeriod.day:
      return kind === 'power'
        ? time.toLocaleString(DateTime.DATETIME_SHORT)
        : Interval.after(time, periodConfig.pointGranularity).toLocaleString(DateTime.DATETIME_SHORT);
    case ChartPeriod.week:
    case ChartPeriod.month:
      return time.toLocaleString(DateTime.DATE_SHORT);
    case ChartPeriod.year:
      return time.toLocaleString({ month: 'long', year: 'numeric' });
    case ChartPeriod.lifetime:
      return time.toLocaleString({ year: 'numeric' });
    default:
      return 'Invalid period';
  }
};

const formatItemValue = (item: { name: string; value: number }, kind: Kind): string => {
  if (item.name === Items.production || item.name === Items.consumption) {
    return formatEnergy(item.value);
  }
  if (item.name === Items.power) {
    return formatPower(item.value);
  }
  if (item.value !== undefined) {
    return kind === 'energy' ? formatEnergy(Number(item.value || 0)) : formatPower(Number(item.value || 0));
  }

  return 'No data';
};

export const ChartToolTip = ({
  payload = [],
  label,
  period,
  kind
}: {
  payload?: {
    name: string;
    color?: string;
    value: number;
  }[];
  label?: number;
  period: ChartPeriod;
  kind: Kind;
}) => {
  if (!label || !payload || payload.length === 0) {
    return null;
  }

  return (
    <PointTooltip>
      <TooltipLabel>{isNumber(label) && formatTooltipTime(DateTime.fromSeconds(label), kind, period)}</TooltipLabel>

      {payload.map((item, index) => (
        <TooltipValue key={item.name || index}>
          <Circle size={8} color={item.color || colors.green} fill={colors.green} />
          {formatItemValue(item, kind)}
        </TooltipValue>
      ))}
    </PointTooltip>
  );
};
