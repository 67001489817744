import React, { useEffect, useCallback, useState } from 'react';
import { ConnectIntegration } from '@generated/types/graphql';
import { useConnectIntegrations } from '@hooks/workspace/connectIntegration';
import { Button, ButtonVariant, ButtonSize } from '@kit/ui/Button';
import { useModal } from '@common/PromiseModal';
import { useNavigate } from '@reach/router';
import { useAppSelector } from '@hooks/store';
import { ConnectIntegrationType } from '@features/Platform/Integrations';
import { selectWorkspaceId } from '@state/selectors';
import { CheckIcon } from '@kit/ui/icons/Check';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import {
  Sections,
  Description,
  H2,
  Header,
  Title,
  Container,
  ProposalToolsContainer,
  ProposalTool,
  ProposalToolDescription
} from './styled';
import Aurora from '../../../assets/svg/aurora.svg';
import { CreateAuroraIntegration } from './Aurora/CreateAuroraIntegration';

export const Integrations = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { data: integrations } = useConnectIntegrations({ companyId });
  const [integrationStatus, setIntegrationStatus] = useState<Record<ConnectIntegrationType, ConnectIntegration>>();
  const navigate = useNavigate();
  const { openModal } = useModal();

  const handleAdd = useCallback(async () => {
    const connectIntegrationId = await openModal<void | number>(
      ({ onClose }) => <CreateAuroraIntegration onClose={onClose} />,
      {
        title: 'Connect Aurora Solar'
      }
    );
    if (connectIntegrationId) {
      navigate(`./${connectIntegrationId}`);
    }
  }, [openModal, navigate]);

  useEffect(() => {
    if (integrations?.length) {
      const integrationStatus: Record<ConnectIntegrationType, ConnectIntegration> = integrations.reduce(
        (acc: any, item) => {
          acc[item.type] = item;

          return acc;
        },
        {}
      );
      setIntegrationStatus(integrationStatus);
    }
  }, [integrations]);

  return (
    <Container>
      <Header>
        <Title>Integrations</Title>
        <Description>
          Connect and manage integrations, enabling efficient data synchronization and streamlined workflows across
          systems.
        </Description>
      </Header>

      <Sections style={{ marginTop: '25px', marginBottom: '50px', gap: '0px' }}>
        <H2>Proposal Tools</H2>
        <ProposalToolsContainer>
          <ProposalTool>
            <img src={Aurora} width={182} height={40} alt="Aurora" />
            <ProposalToolDescription>
              Get all data for solar micro-inverters, battery energy storage, and EV charging stations for your
              customers
            </ProposalToolDescription>
            <div>
              {integrationStatus && integrationStatus[ConnectIntegrationType.AURORA_SOLAR] ? (
                <Badge
                  size={BadgeSize.Large}
                  color="#009688"
                  bgColor="#cdf3df"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`./${integrationStatus[ConnectIntegrationType.AURORA_SOLAR].id}`)}
                >
                  <CheckIcon size={12} />
                  Connected
                </Badge>
              ) : (
                <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} onClick={handleAdd}>
                  Connect
                </Button>
              )}
            </div>
          </ProposalTool>
        </ProposalToolsContainer>
      </Sections>
    </Container>
  );
};
