export type AuroraProject = {
  id: string;
  name: string;
  address: string;
  createdAt: string;
};

export type AuroraAgreement = {
  id: string;
  name: string;
  createdAt: string;
};

export type CreateConnectIntegrationDTO = {
  token: string;
  tenant: string;
};

export type UpdateConnectIntegrationDTO = {
  integrationId: number;
  blueprintId: number;
  matchClientColumnId: number;
  matchProjectColumnId: number;
  matchFoundStrategy: string;
  propertiesMap: Record<string, string>;
};

export type AuroraProjectDTO = {
  companyId: number;
  projectId: string;
  projectName: string;
  agreementId: string;
  agreementName: string;
};

export enum ConnectIntegrationType {
  AURORA_SOLAR = 'AURORA_SOLAR',
  OPEN_SOLAR = 'OPEN_SOLAR'
}

export enum ConnectHistoryStatus {
  SUCCESSFUL = 'SUCCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED'
}
